import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import "./App.css";


import 'bootstrap/dist/css/bootstrap.min.css';
import WomenKalyanPage from './pages/WomenKalyan';  
import Faq from "./pages/Faq";
// import Interview from "./pages/Interview";

import Cookies  from "js-cookie";

import Contact from "./pages/Contact";
import Documents from "./pages/Documents";
import Privacy from "./pages/Privacy";
import HomeLayout from "./layout/home-layout";
import Welcomw from "./components/home/Welcome";
import About from "./pages/About";
import Advocat from "./pages/Advocate";
import Resource from "./pages/Resource";
import ResourceDetailPage from "./pages/ResourceDetailPage.js";
import Blog from "./pages/Blog";
import Service from "./pages/Service";
import Student from "./pages/Student";
import Faculty from "./pages/Faculty";
import AdvocatePage from "./pages/AdvocatePage";
import Litigants from "./pages/Litigants";
import Judiciary from "./pages/Judiciary";
import SiteMap from "./pages/SiteMap";
import Disclaimer from "./pages/Disclaimer";
import Link from "./pages/Link";
import Policies from "./pages/Policies";
import Krishna from './Advocatedetails/Krishna.js';
import Anju from "./Advocatedetails/Anju";
import Rajamani from "./Advocatedetails/Rajamani";
import Sanjay from './Advocatedetails/Sanjay'
import Loginx from "./Admin/Loginx.js"; 
import Test from "./pages/Tests";
import Login from './test/Login';
import Register from './test/Register';
import Dashboard from './test/Dashboard';
import Otp from './test/Otp';
import Error from './test/Error';
import Quiz from "./Quiz/Quiz.js";
import Quiz2 from "./Quiz/Quiz2.js";
import Quiz3 from "./Quiz/Quiz3.js";
import Quiz4 from "./Quiz/Quiz4.js";
import Quiz5 from "./Quiz/Quiz5.js";
import Quiz6 from "./Quiz/Quiz6.js";
import Quiz7 from "./Quiz/Quiz7.js";
import Quiz8 from "./Quiz/Quiz8.js";
import Quiz9 from "./Quiz/Quiz9.js";
import Quiz10 from "./Quiz/Quiz10.js";
import AdminDashboard from "./Admin/AdminDashboard.js";
import BlogDetail from "./pages/BlogDetail.js";
// import './components/locomotive.css';



function App() {
  return (
    <>
      <Router >
        <Routes>
          <Route path="/" exact element={<HomeLayout />}>
          <Route path="/women-kalyan" element={<WomenKalyanPage />} />
            <Route path="welcome" element={<Welcomw />} />
            {/* <Route path="interview" element={<Interview />} /> */}
            <Route path="about" element={<About />} />
            <Route path="Media" element={<Advocat />} />


            <Route path="Resource" element={<Resource />} />
            <Route path="Resource/:resourceId" element={<ResourceDetailPage />} />
            <Route path="Blog" element={<Blog />} />
            <Route path="Service" element={<Service />} />
            <Route path="Student" element={<Student />} />
            <Route path="Faculty" element={<Faculty />} />
            <Route path="AdvocatePage" element={<AdvocatePage />} />
            <Route path="Litigants" element={<Litigants />} />
            <Route path="Judiciary" element={<Judiciary />} />
            <Route path="advocate-form" element={<SiteMap />} />
            <Route path="Disclaimer" element={<Disclaimer />} />
            <Route path="Link" element={<Link />}/>
            <Route path="Policies" element={<Policies />} />
            <Route path="contact" element={<Contact />} />
            <Route path="profile-sanjeev" element={<Krishna />} />
            <Route path="profile-Anju" element={<Anju />} />
            <Route path="profile-Rajamani" element={<Rajamani />} />
            <Route path="profile-Sanjay" element={<Sanjay />} />
            <Route path="Test" element={<Test/>}/>
            <Route path="/blogs/:id" component={<BlogDetail/>} />
            {/* =========== */}
            <Route path='/test_Login' element={<Login />} />
            <Route path='/Quiz' element={<Quiz />} />
            <Route path='/Quiz2' element={<Quiz2 />} />
            <Route path='/Quiz3' element={<Quiz3 />} />
            <Route path='/Quiz4' element={<Quiz4 />} />
            <Route path='/Quiz5' element={<Quiz5 />} />
            <Route path='/Quiz6' element={<Quiz6 />} />
            <Route path='/Quiz7' element={<Quiz7 />} />
            <Route path='/Quiz8' element={<Quiz8 />} />
            <Route path='/Quiz9' element={<Quiz9 />} />
            <Route path='/Quiz10' element={<Quiz10 />} />
        <Route path='/register' element={<Register />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/user/otp' element={<Otp />} />
        <Route path='*' element={<Error />} />
            <Route path="documents" element={<Documents />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="faq" element={<Faq />} />
            <Route index element={<Welcomw />} />
         
          </Route>  
          <Route path="admin" element={ Cookies.get("jwt")?<AdminDashboard />:<Loginx />} />
    
        </Routes>
      </Router>
    </>
  );
}

export default App;
