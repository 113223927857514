import React from 'react';
import "../App.css";

import { FaLinkedin, FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import sanjay from "../images/sanjay.jpg"
import anju from "../images/anju.jpg"
import sanjiv from "../images/advsanjeev.jpg"
import rajamani from "../images/rajamani.jpg"


function AdvocatePage() {
  return (
    <>
      <div className="centerCol cv ">
        <div className="welcomeText">
          <h3 className='m-4'><span> Our Advocates </span></h3> 



          <Card> 
          <Card.Body> 
            <div className='row1'> 
          <div className='higw1'> 
          <img src={sanjiv} width={180} alt='fghj' />
          {/* <div className='advs'>
          <Link to= "https://www.linkedin.com/company/kataria-and-associates/">
                 <FaLinkedin/></Link>
                 <a href= 'mailto:cssanjeevkataria@gmail.com'>
                  <FaMailBulk/></a>
                  </div> */}
           </div>
          <div className='higw2'> 
          <h4 className='center'> Advocate Sanjeev Kumar</h4>
                  <p>Adv Sanjeev Kumar is an advocate practicing at  Supreme Court, High Court, District Courts and NCLT for past 13 years. He is gold medalist in Bachelor of Laws, from North Eastern Hill University Shillong in year 2003 and also an associate member of ICSI. 
                  </p>
                 <center>  <Link to="/profile-sanjeev">  <button className='more_btn'>More Information</button>  </Link></center>

          
           </div>
         
            </div>
          </Card.Body>
         </Card>

     <Card> 
          <Card.Body> 
            <div className='row1'> 
          <div className='higw1'> 
          <img src={rajamani} alt='fghj' />
          {/* <div className='advs'>
          <Link to= "https://www.linkedin.com/company/kataria-and-associates/">
                 <FaLinkedin/></Link>
                 <a href= 'mailto:prithvish@tranlife.in'>
                  <FaMailBulk/></a>
                  </div> */}
           </div>

          <div className='higw2'> 
          <h4 className='center'> Advocate Prithvish Rajamani </h4>
                  <p> Mr. Prithvish Rajamani is one of the most sought-after family courts’ mediators in India with a success rate well above the industry average. With over 10 years’ experience and 80+ successful settlement agreements over 18,000 hrs., he has been recognised as one of the leading mediators in Bangalore for the 3 consecutive years. 
                  </p>
                 <center>  <Link to="/profile-Rajamani">  <button className='more_btn'>More Information</button>  </Link></center>

          
           </div>
        
            </div>
          </Card.Body>
         </Card>
           



         <Card> 
          <Card.Body> 
            <div className='row1'> 
          <div className='higw1'> 
          <img src={sanjay} width={180} alt='fghj' />
          {/* <div className='advs'>
          <Link to= "https://www.linkedin.com/company/kataria-and-associates/">
                 <FaLinkedin/></Link>
                 <a href= 'mailto:cssanjeevkataria@gmail.com'>
                  <FaMailBulk/></a>
                  </div> */}
           </div>
          <div className='higw2'> 
          <h4 className='center'> Advocate Sanjay Chavre</h4>
                  <p> Mr. Chavre had done LLB  from Maharishi Law School ,Maharishi University of Information Technology,   NOIDA. He is also a Mechanical Engineer with Post Graduate Diploma in Business Management equivalent to MBA.
                  </p>
                 <center>  <Link to="/profile-Sanjay">  <button className='more_btn'>More Information</button>  </Link></center>

          
           </div>
        
            </div>
          </Card.Body>
         </Card>
           



    






         
        
         <Card> 
          <Card.Body> 
            <div className='row1'> 
          <div className='higw1'> 
          <img src={anju} width={180} alt='fghj' />
          {/* <div className='advs'>
          <Link to= "https://www.linkedin.com/in/advocate-anju-arora-514826150?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                 <FaLinkedin/></Link>
                 <a href= 'mailto:aroraanju576@gmail.com'>
                  <FaMailBulk/></a>
                  </div> */}
           </div>
          <div className='higw2'> 
          <h4 className='center'> Advocate Anju Arora</h4>
                  <p> Advocate Anju Arora was a scholar at LLB from Kurushreta University in the session of 2018-2021. She excelled as an student in both co-curricular activities such as debates, Mock Courts, Mock Mediations etc and also academic activities.
                  </p>
                 <center>  <Link to="/profile-Anju">  <button className='more_btn'>More Information</button>  </Link></center>

          
           </div>
      
            </div>
          </Card.Body>
         </Card>

         
        

      

         
        </div>
      </div>
    </>
  )
}

export default AdvocatePage

