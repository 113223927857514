import React from 'react'
import CardSlider from './CardSlider';


function CardData() {
     

  return (
   <> 
    <div className="advocatecard">
      <CardSlider  />
    </div>
   </>
  )
}

export default CardData