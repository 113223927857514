import React, { useState } from 'react'
import { NavLink, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import { sentOtpFunction } from "../services/Apis";
import Spinner from 'react-bootstrap/Spinner';
import "../styles/mix.css"

const Login = () => {

    const [email, setEmail] = useState("");
    const [spiner,setSpiner] = useState(false);

    const navigate = useNavigate();



    // sendotp
    const sendOtp = async (e) => {
        e.preventDefault();

        if (email === "") {
            toast.error("Enter Your Email !")
        } else if (!email.includes("@")) {
            toast.error("Enter Valid Email !")
        } else {
            setSpiner(true)
            const data = {
                email: email
            }

            const response = await sentOtpFunction(data);

            if (response.status === 200) {
                setSpiner(false)
                navigate("/user/otp",{state:email})
            } else {
                toast.error(response.response.data.error);
            }
        }
    }

    return (
        <>
            <section className='signup_class'>
         <div className='login-test'>
                <div className="form_data">
                    <div className="form_heading">
                        <h1 >Welcome to Kataria & Associates</h1>
                        <p> log in to your account</p>  
                    </div>
                    <form>
                        <div className="form_input">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="" onChange={(e) => setEmail(e.target.value)} placeholder='Enter Your Email Address' />
                        </div>
                        <button className='btn' onClick={sendOtp}>Login
                        {
                            spiner ? <span><Spinner animation="border" /></span>:""
                        }
                        </button>
                        <p>Don't have an account <NavLink to="/register">Sign up</NavLink> </p>
                    </form>
                </div>
                <ToastContainer />
            {/* </section> */}
            {/* <section> */}
  <div className='test-data'>
            <div className='test-titles'>
              <h2>List of tests.</h2>
<h1>1. Law for Arbitration in India</h1>
<h1>2. Arbitration in India Law</h1>
<h1>3. Hindu Divorce Law</h1>
<h1>4. Hindu Adoption Law</h1>
<h1>5. Child Custody Law</h1>
<h1>6. Hindu Marriage Act 1955</h1>
<h1>7. Special Marriage</h1>
<h1>8. Maintenance Law of India</h1>
<h1>9. Muslim Divorce Law</h1>
<h1>10. Wills and Probate Law</h1>
            </div>
            <ToastContainer />
          </div>
      </div>
      </section>
        </>
    )
}

export default Login