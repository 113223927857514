import React, { useEffect, useState } from "react"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import "./AdminAll.css"
import {BACKEND_URL} from "../services/helper.js"


function Loginx() {
  const history=useNavigate();

  const [email,setemail]=useState('')
  const [password,setPassword]=useState('')

  async function submit(e){
      e.preventDefault();
      try{ 
          await axios.post(`${BACKEND_URL}/auth/login`,{
            email,password
          })
          .then(res=>{
            const { token } = res.data;
            console.log(token,"234567890")
            Cookies.set('jwt', token);
            // setIsLoggedIn(true);
            const user = jwt_decode(token)
          
              if(res.status==200){
                  history("/admin",{state:{id:email}})
                  window.location.reload();
                }
              else if(res.data=="notexist"){
                  alert("User have not sign up")
              } 
          })
          .catch(e=>{
              alert("wrong details")
              console.log(e);
          })

      }

      catch(e){
          console.log(e);

      }

  }

  
 


  return (
    <div className="login_bg">
      <div className="login">

          <h3>Login dashboard</h3>

          <form action="POST">
              <input type="text" className="email_email" onChange={(e) => { setemail(e.target.value) }} placeholder="email"  /> <br></br>
              <input type="password" className="passwardwer" onChange={(e) => { setPassword(e.target.value) }} placeholder="Password"  /> <br></br>
              <button className="login_button"  onClick={submit}> Login</button>
             

          </form>¯

          <br />

      </div>
      </div>
  )
}

export default Loginx