
// import React, { useState, useEffect, useMemo, useRef } from 'react';
// // import React, { useState, useRef, useMemo } from 'react';
// import JoditEditor from 'jodit-react';
// import axios from 'axios';
// import { Button, Container } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link } from 'react-router-dom';
// import BlogDetail from './BlogDetail';
// import { ToastContainer, toast } from 'react-toastify';
// import { BACKEND_URL } from "../services/helper.js";

// function Blog() {


//   // const [placeholder, setPlaceholder] = useState('Start typings...');
//     const editor = useRef(null);
//     // const [formData.blogg_paragraph, setContent] = useState('');
  
  

//   const [formData, setFormData] = useState({
//     fname: "",
//     mname: "",
//     lname: "",
//     mobile: "",
//     email: "",
//     whatsapp_no: "",
//     blogg_heading: "",
//     blogg_paragraph: "",
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await axios.post(`${BACKEND_URL}/blog/add`, formData);
//       if (response.status === 201) {
//         toast.success("Blog created successfully");
//         setFormData({
//           fname: "",
//           mname: "",
//           lname: "",
//           mobile: "",
//           email: "",
//           whatsapp_no: "",
//           blogg_heading: "",
//           blogg_paragraph: "",
//         });
//       } else {
//         console.error('Blog creation failed:', response.data);
//       }
//     } catch (error) {
//       console.error('Error creating blog:', error);
//     }
//   };

//   const [blogs, setBlogs] = useState([]);
//   const [visibleBlogs, setVisibleBlogs] = useState(4);
//   const [selectedBlog, setSelectedBlog] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/blog/get_blogs`);
//         setBlogs(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching blogs:', error);
//         setLoading(false);
//       }
//     };

//     fetchBlogs();
//   }, []);

//   const handleReadMore = (blog) => {
//     setSelectedBlog(blog);
//   };

//   const handleCloseModal = () => {
//     setSelectedBlog(null);
//   };

//   const visibleBlogsList = useMemo(() => {
//     return blogs.slice(0, visibleBlogs);
//   }, [blogs, visibleBlogs]);

//   return (
//     <>
//       <div className="centerCol">
//         <div className="welcomeText">
//           <h3>Blog Page</h3>
//           <p><b>Note:</b> Please fill the forms for registration to blog.</p>

//           <Container>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">First Name *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="First Name"
//                   aria-label="First Name"
//                   name='fname'
//                   aria-describedby="basic-addon1"
//                   value={formData.fname}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Middle Name</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Middle Name"
//                   aria-label="Middle Name"
//                   name='mname'
//                   aria-describedby="basic-addon1"
//                   value={formData.mname}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Last Name *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Last Name"
//                   aria-label="Last Name"
//                   name='lname'
//                   aria-describedby="basic-addon1"
//                   value={formData.lname}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Email *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Email"
//                   aria-label="Email"
//                   name='email'
//                   aria-describedby="basic-addon1"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Mobile No. *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Mobile No."
//                   aria-label="Mobile No."
//                   name='mobile'
//                   aria-describedby="basic-addon1"
//                   value={formData.mobile}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Whatsapp No. *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Whatsapp No."
//                   aria-label="Whatsapp No."
//                   name='whatsapp_no'
//                   aria-describedby="basic-addon1"
//                   value={formData.whatsapp_no}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Blogg Heading*</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Blogg Heading"
//                   aria-label="Blogg Heading"
//                   name='blogg_heading'
//                   aria-describedby="basic-addon1"
//                   value={formData.blogg_heading}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Blog Paragraph*</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 {/* <Form.Control
//                   as="textarea"
//                   placeholder="max 500 words"
//                   name="blogg_paragraph"
//                   style={{ height: '100px' }}
//                   value={formData.blogg_paragraph}
//                   onChange={handleChange}
//                 /> */}
//                 <JoditEditor
// 			ref={editor}
//       name="blogg_paragraph"
// 			value={formData.blogg_paragraph}
// 			// config={config}
// 			tabIndex={1} // tabIndex of textarea
// 			onChange={newContent =>  setFormData(newContent)} // preferred to use only this option to update the content for performance reasons
// 			// onChange={newContent => {}}
// 		/>
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}></Col>
//               <Col sm={4}>
//                 <Button onClick={handleSubmit}>Submit</Button>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </div>

//       <div className="blog-container1">
//         <Container>
//           {loading ? (
//             <div className="loader"></div>
//           ) : (
//             <>
//               <Row>
//                 <Col className="m-2">
//                   <h3 className='my-4'>Here are our recent blogs.</h3>
//                   <Row>
//                     <ul>
//                       {visibleBlogsList.map((blog) => (
//   <li key={blog._id} className="blogcss">
//     <h3>{blog.blogg_heading}</h3>
//     <button onClick={() => handleReadMore(blog)} className="btnblog">Read More</button>
//   </li>
// ))}
//                     </ul>
//                   </Row>
//                 </Col>
//               </Row>
//               <Row>
//                 {visibleBlogs < blogs.length && (
//                   <center className="m-2">
//                     <Link to="/Blog">
//                       <Button className="btn_blog btns" onClick={() => setVisibleBlogs((prev) => prev + 4)}>More Blog »</Button>
//                     </Link>
//                   </center>
//                 )}
//               </Row>
//             </>
//           )}
//           {selectedBlog && <BlogDetail isOpen={!!selectedBlog} onClose={handleCloseModal} blog={selectedBlog} />}
//         </Container>
//       </div>

//       <ToastContainer />
//     </>
//   );
// }

// export default Blog;
















// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, Container } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link } from 'react-router-dom';
// import BlogDetail from './BlogDetail';
// import { ToastContainer, toast } from 'react-toastify';
// import { BACKEND_URL } from "../services/helper.js";


// function Blog() { 
//   const [formData, setFormData] = useState({
//     fname: "",
//     mname: "",
//     lname: "",
//     mobile: "",
//     email: "",
//     whatsapp_no: "",
//     blogg_heading: "",
//     blogg_paragraph: "",
//   });

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await axios.post(`${BACKEND_URL}/blog/add`, formData);
//       if (response.status === 201) {
//         toast.success("Blog created successfully");
//         console.log('Blog created successfully');
//       } else {
//         console.error('Blog creation failed:', response.data);
//       }
//     } catch (error) {
//       console.error('Error creating blog:', error);
//     }
//   };

//   const [blogs, setBlogs] = useState([]);
//   const [visibleBlogs, setVisibleBlogs] = useState(4);
//   const [selectedBlog, setSelectedBlog] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/blog/get_blogs`);
//         setBlogs(response.data);
//         setLoading(false); // Set loading to false after data is fetched
//       } catch (error) {
//         console.error('Error fetching blogs:', error);
//         setLoading(false); // Set loading to false in case of error
//       }
//     };

//     fetchBlogs();
//   }, []);

//   const handleReadMore = (blog) => {
//     setSelectedBlog(blog);
//   };

//   const handleCloseModal = () => {
//     setSelectedBlog(null);
//   };

//   return (
//     <>
//       <div className="centerCol">
//         <div className="welcomeText">
//           <h3>Blog Page</h3>
//           <p><b>Note:</b> Please fill the forms for registration to blog.</p>

//           <Container>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">First Name *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="First Name"
//                   aria-label="First Name"
//                   name='fname'
//                   aria-describedby="basic-addon1"
//                   value={formData.fname}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Middle Name</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Middle Name"
//                   aria-label="Middle Name"
//                   name='mname'
//                   aria-describedby="basic-addon1"
//                   value={formData.mname}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Last Name *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Last Name"
//                   aria-label="Last Name"
//                   name='lname'
//                   aria-describedby="basic-addon1"
//                   value={formData.lname}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Email *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Email"
//                   aria-label="Email"
//                   name='email'
//                   aria-describedby="basic-addon1"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Mobile No. *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Mobile No."
//                   aria-label="Mobile No."
//                   name='mobile'
//                   aria-describedby="basic-addon1"
//                   value={formData.mobile}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Whatsapp No. *</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Whatsapp No."
//                   aria-label="Whatsapp No."
//                   name='whatsapp_no'
//                   aria-describedby="basic-addon1"
//                   value={formData.whatsapp_no}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Blogg Heading*</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   placeholder="Blogg Heading"
//                   aria-label="Blogg Heading"
//                   name='blogg_heading'
//                   aria-describedby="basic-addon1"
//                   value={formData.blogg_heading}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}>
//                 <Form.Label htmlFor="basic-url">Blog Paragraph*</Form.Label>
//               </Col>
//               <Col sm={4}>
//                 <Form.Control
//                   as="textarea"
//                   placeholder="max 500 words"
//                   name="blogg_paragraph"
//                   style={{ height: '100px' }}
//                   value={formData.blogg_paragraph}
//                   onChange={handleChange}
//                 />
//               </Col>
//             </Row>
//             <Row className="justify-content-md-center m-2">
//               <Col sm={5}></Col>
//               <Col sm={4}>
//                 <Button onClick={handleSubmit}>Submit</Button>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </div>

//       <div className="blog-container1">
//         <Container>
//           {loading ? ( // Display animated loader when loading
//             <div className="loader"></div>
//           ) : (
//             <>
//               <Row>
//                 <Col className="m-2">
//                   <h3 className='my-4'>Here are our recent blogs.</h3>
//                   <Row>
//                     <ul>
//                       {blogs.slice(0, visibleBlogs).map((blog) => (
//                         <li key={blog._id} className="blogcss">

//                           <h3>{blog.blogg_heading}</h3>
//                           <button onClick={() => handleReadMore(blog)} className="btnblog">Read More</button>
//                         </li>
//                       ))}
//                     </ul>
//                   </Row>
//                 </Col>
//               </Row>
//               <Row>
//                 {visibleBlogs < blogs.length && (
//                   <center className="m-2">
//                     <Link to="/Blog">
//                       <Button className="btn_blog btns" onClick={() => setVisibleBlogs((prev) => prev + 4)}>More Blog »</Button>
//                     </Link>
//                   </center>
//                 )}
//               </Row>
//             </>
//           )}
//           {selectedBlog && <BlogDetail isOpen={!!selectedBlog} onClose={handleCloseModal} blog={selectedBlog} />}
//         </Container>
//       </div>

//       <ToastContainer />
//     </>
//   );
// }

// export default Blog;



import React, { useState, useEffect, useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import BlogDetail from './BlogDetail';
import { ToastContainer, toast } from 'react-toastify';
import { BACKEND_URL } from "../services/helper.js";


function Blog() {
  const editor = useRef(null);
  const [formData, setFormData] = useState({
    fname: "",
    mname: "",
    lname: "",
    mobile: "",
    email: "",
    whatsapp_no: "",
    blogg_heading: "",
    blogg_paragraph: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (newContent) => {
    setFormData({ ...formData, blogg_paragraph: newContent });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${BACKEND_URL}/blog/add`, formData);
      if (response.status === 201) {
        toast.success("Blog created successfully");
        setFormData({
          fname: "",
          mname: "",
          lname: "",
          mobile: "",
          email: "",
          whatsapp_no: "",
          blogg_heading: "",
          blogg_paragraph: "",
        });
      } else {
        console.error('Blog creation failed:', response.data);
      }
    } catch (error) {
      console.error('Error creating blog:', error);
    }
  };

  const [blogs, setBlogs] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState(4);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/blog/get_blogs`);
        setBlogs(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleReadMore = (blog) => {
    setSelectedBlog(blog);
  };

  const handleCloseModal = () => {
    setSelectedBlog(null);
  };

  const visibleBlogsList = useMemo(() => {
    return blogs.slice(0, visibleBlogs);
  }, [blogs, visibleBlogs]);

  return (
    <>
      <div className="centerCol">
        <div className="welcomeText">
          <h3>Blog Page</h3>
          <p><b>Note:</b> Please fill the forms for registration to blog.</p>

          <Container>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">First Name *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="First Name"
                  aria-label="First Name"
                  name='fname'
                  aria-describedby="basic-addon1"
                  value={formData.fname}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Middle Name</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Middle Name"
                  aria-label="Middle Name"
                  name='mname'
                  aria-describedby="basic-addon1"
                  value={formData.mname}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Last Name *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Last Name"
                  aria-label="Last Name"
                  name='lname'
                  aria-describedby="basic-addon1"
                  value={formData.lname}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Email *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Email"
                  aria-label="Email"
                  name='email'
                  aria-describedby="basic-addon1"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Mobile No. *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Mobile No."
                  aria-label="Mobile No."
                  name='mobile'
                  aria-describedby="basic-addon1"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Whatsapp No. *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Whatsapp No."
                  aria-label="Whatsapp No."
                  name='whatsapp_no'
                  aria-describedby="basic-addon1"
                  value={formData.whatsapp_no}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Blogg Heading*</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Blogg Heading"
                  aria-label="Blogg Heading"
                  name='blogg_heading'
                  aria-describedby="basic-addon1"
                  value={formData.blogg_heading}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Blog Paragraph*</Form.Label>
              </Col>
              <Col sm={4}>
                <JoditEditor
                  ref={editor}
                  value={formData.blogg_paragraph}
                  tabIndex={1}
                  onChange={handleEditorChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}></Col>
              <Col sm={4}>
                <Button onClick={handleSubmit}>Submit</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="blog-container1">
        <Container>
          {loading ? (
            <div className="loader"></div>
          ) : (
            <>
              <Row>
                <Col className="m-2">
                  <h3 className='my-4'>Here are our recent blogs.</h3>
                  <Row>
                    <ul>
                      {visibleBlogsList.map((blog) => (
                        <li key={blog._id} className="blogcss">
                          <h3>{blog.blogg_heading}</h3>
                          <button onClick={() => handleReadMore(blog)} className="btnblog">Read More</button>
                        </li>
                      ))}
                    </ul>
                  </Row>
                </Col>
              </Row>
              <Row>
                {visibleBlogs < blogs.length && (
                  <center className="m-2">
                    <Link to="/Blog">
                      <Button className="btn_blog btns" onClick={() => setVisibleBlogs((prev) => prev + 4)}>More Blog »</Button>
                    </Link>
                  </center>
                )}
              </Row>
            </>
          )}
          {selectedBlog && (
            <BlogDetail
              isOpen={!!selectedBlog}
              onClose={handleCloseModal}
              blog={selectedBlog}
            />
          )}
        </Container>
      </div>

      <ToastContainer />
    </>
  );
}

export default Blog;
