import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Button, Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import {FaLinkedin,FaTwitter,FaWhatsapp,FaFacebook,FaMobileAlt,FaMailBulk } from "react-icons/fa";
import  { MdLocationOn } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import {BACKEND_URL} from "../services/helper"
import bglogoo from "../images/bglogo.png";
import { FaXTwitter } from "react-icons/fa6";
import bglogo from "../images/bglogo.png";

const Footer = () => { 
  const [formData, setFormData] = useState({
email: "",
});
const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
};
  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${BACKEND_URL}/email/send`, formData);
      if (response.status==201) {
        toast.success("subscribe successfully");
      
      } else {
        console.error('Blog creation failed:', response.data);
        // Handle error
      }
    } catch (error) {
      console.error('Error creating blog:', error);
      // Handle error
    }
  }; 
  return (
    <>
<Container fluid className="footer_body"> 
    <Container  > 
    <div className="center"> <h4>  KATARIA & ASSOCIATES </h4></div> 
    <hr/>
    <Row >

        <div className='footer_class_group'>
      <div className='footer_class'>
        <Col className="footer_reachus">
          <h4 className="">Reach us</h4>
          <p className=" "> <FaMobileAlt/><FaWhatsapp/>:+ 91 9650756107</p>
          <p className=""><FaMailBulk/>: cssanjeevkataria@gmail.com  </p>
          <p > <MdLocationOn /> 443, Civil Wing, Tis Hazari Court,Delhi -54  </p>
        </Col>
        {/* <Col xs={12} md={2} className="f_col  m-2">
         <h4  className="center"> Company</h4>
         <ul className="footer_list"> 
          <li> <Link to="/AdviceSought"> » Media and news</Link></li>
          <li> <Link to="/about">» About </Link></li>
          <li>  <Link to="/contact">» Contact </Link>  </li>
          <li> <Link to="/Blog">» Blog </Link>  </li>
         </ul>
        </Col> */}
          {/* <Col xs={12} md={3} className="f_col m-2">
       <h4 className="center"> Legal</h4> 
       <ul className="footer_list sm">
     
       
         </ul>
        </Col> */}
          <Col  className=" footer_quick">
       <h4 className='center' > Quick Links</h4> 
       <ul className="footer_list ">
          <li><a href="https://tranlife.in" target="_blank" > » Visit tranlife.in</a> </li>
          <li> <Link to="/Policies"> » Privacy Policy </Link>  </li>
       <li> <Link to="/Disclaimer"> » Disclaimer </Link>  </li>
       <li> <Link to="/Faq"> » Q & A </Link>  </li>
         
         </ul>
        </Col>

        </div>

        <Col className="">
        <h4  className="news_letter"> News letter<p>Subscribe to our newsletter to stay up to date<br></br> with our latest news and promotions ⇩⇩</p></h4>
        <div className='news_input'>
        <Form.Control   style={{ width: "300px", height: "40px", marginTop: "30px" }}
           placeholder="Enter your Email" 
           className="input_width" 
           name="email"
         
           value={formData.email}
           onChange={handleChange}
           /> 
              <Button className=" sub-btn mx-3" onClick={handleSubmit}> subscribe</Button>
              </div>
      
           <ul className="row_flex footer_socialicon">

             
<li className="m-1 "> <Link to="https://www.facebook.com/profile.php?id=61554756211576">  <FaFacebook className="mnop"/> </Link>

</li>
<li className="m-1">
  <Link to= "https://www.linkedin.com/company/kataria-and-associates/">
   <FaLinkedin className="mnop"/></Link></li>
   <li className="m-1" ><Link to = "https://wa.me/+919650756107?text=Hi+Advocate+Anju+I+am+interested+in+your+services+for+my+case+with+your+company+Kataria+and+Associates+"> <FaWhatsapp className="mnop"/></Link></li>
<li className="m-1"> 
<Link to = "https://twitter.com/Katariassociate/likes">
<FaXTwitter className="mnop" /></Link></li>



</ul>
           
        
        </Col>
        <div className="footer_logo"><Link to = "/"><img src={bglogo} alt="fghj" width={100}  /></Link></div>
           
            </div>
          
      </Row>
      
        <hr/>
        <p style={{ textAlign: "center", fontSize: "12px" }} className="center"><b>Kataria & Associates  @ 2023 All rights reserved</b>  </p>
    </Container >
    <ToastContainer />
</Container>
      

     
    </>
  );
};

export default Footer;
