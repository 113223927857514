// ResourceDetailPage.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {BACKEND_URL} from "../services/helper"


const ResourceDetailPage = () => {
  const { resourceId } = useParams();
  const [resource, setResource] = useState(null);

  useEffect(() => {
    fetch(`${BACKEND_URL}/resource/get_resources`)
      .then(response => response.json())
      .then(data => {
        const foundResource = data.find(item => item._id === resourceId);
        console.log(foundResource)
        setResource(foundResource);
      })
      .catch(error => console.error('Error fetching resources:', error));
  }, [resourceId]);

  useEffect(() => {
    if (resource) {
      document.title = resource.resource_heading; // Set page title to resource heading
    }
  }, [resource]);

  if (!resource) {
    return <div className='loader'></div>;
  }

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <h1 style={{ textAlign: 'center', fontSize: '24px', marginBottom: '60px' }}>
        {resource.resource_heading}
      </h1>
      <p style={{ fontSize: '18px', lineHeight: '1.6',display : 'flex', justifyContent : "center"}}>
        {/* {resource.resource_paragraph} */}
        <iframe src= {resource.resource_url}
        style={{width: "70%", height: "100vh", border: "none"}}
        type="application/pdf">
        
        </iframe>
      </p>
    </div>
  );
};

export default ResourceDetailPage;
