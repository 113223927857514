import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import anju from "../images/anju.jpg"
import { FaLinkedin, FaMailBulk,FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../App.css";


function Anju() {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <Container>
          <h3 className='center '><span>Our Advocate profile section</span></h3>
        </Container>

        <Container>
          <Row>
            <Col> <img src={anju} alt='werty'  className='m-2 imgcus1' />
              <div className='red12'>
                <ul className='rowflex'>
                  <li className='advs'>
                  <Link to= "https://www.linkedin.com/in/advocate-anju-arora-514826150?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                 <FaLinkedin/></Link>
                  </li>
                  <li className='advs'>
                  <a href= 'mailto:aroraanju576@gmail.com'>
                  <FaMailBulk/>  </a> </li>  
                  <li className='advs'><Link to = "https://wa.me/+917988291750?text=Hi+Advocate+Anju+I+am+interested+in+your+services+for+my+case+with+your+company+Kataria+and+Associates+"> <FaWhatsapp/></Link></li>

                
                </ul>
              </div>
              <Col>
             <p> 1. Chamber :</p>
             <h6  className='ttrr12'>Advocate Anju Arrora </h6>
             <h6  className='ttrr12'> 443, 443A, Civil Wing, Tis Hazari CourtDelhi 110 054</h6>
             <h6  className='ttrr12'>Delhi -54 </h6>
             <h6  className='ttrr12'> Mobile and what-app no. +917988291750,</h6>
             <h6  className='ttrr12'> Email: aroraanju576@gmail.com </h6>
            </Col>
            
            <Col> 
            <p>   2. Office:</p>
<h6 className='ttrr12 '> Kataria and Associates,</h6>
<h6 className='ttrr12 '> 443, 443A, Civil Wing,</h6>
<h6 className='ttrr12 '> Tis Hazari Court</h6>
<h6 className='ttrr12 '> Delhi -54 </h6>
</Col>
            </Col>
            <Col className='m-2 il '>
              <h3> <b> Legal Background and Qualifications</b></h3>
              <p>Advocate Anju Arora was a scholar at LLB from Kurushreta University in the session of 2018-2021. She excelled as an student in both co-curricular activities such as debates, Moot Courts, Moot Mediations etc and also academic activities.</p>
               <br></br>
               <p>She also graduated in 2009 BA (Public Administration and Maths ) also from Kurushrertra University.
She also obtained Diploma in Junior Basic Training for education from MP Board in the year 2013. 
She also did a certificate course in Food and Nutrition from IGNOU in the year 2015. In between, she was teaching children. 
</p>
<br></br>
<p>She specializes in Family Law, Trust, NI Act and Criminal Acts ( IPC etc). She has appeared in more than 100 cases in Courts like District Courts, Family Courts, MM, Delhi High Court etc. She is well know for her legal acumen and analysis. She does client counselling well from human angle. She is interested in pro bono work. Her special interest is legal support to destitute women and child. She has thorough knowledge of law. She is a very efficient and good drafter of petitions, complaints , written statements, appeals etc.    </p>

         
            </Col>
          </Row>
          <Container> 
            <Row> 
    

            </Row>
          </Container>
        </Container>
       
      </div>
    </div>
  )
}

export default Anju