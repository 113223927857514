import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Table from "react-bootstrap/Table";
import Cookies from "js-cookie";
import Accordion from "react-bootstrap/Accordion";
import { ToastContainer, toast } from "react-toastify";
import { BACKEND_URL } from "../services/helper";

function AdminDashboard() {
  const [studentList, setStudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [litigantList, setlitigantList] = useState([]);
  const [advocateList, setadvocateList] = useState([]);
  const [judiciaryList, setjudiciaryList] = useState([]);
  const [blogList, setblogList] = useState([]);
  useEffect(() => {
    fetchStudentList();
    fetchfacultyList();
    fetchlitigantList();
    fetchadvocateList();
    fetchblogList();
    fetchjudiciaryList();
  }, []);

  const fetchStudentList = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/student/student_list`);
      const studentsWithId = response.data.map((student, index) => ({
        ...student,
        id: index + 1,
      }));
      setStudentList(studentsWithId);
    } catch (error) {
      console.error("Error fetching student list:", error);
    }
  };
  const fetchfacultyList = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/faculty/faculty_list`);
      const fc = response.data.map((student, index) => ({
        ...student,
        id: index + 1,
      }));
      setfacultyList(fc);
    } catch (error) {
      console.error("Error fetching student list:", error);
    }
  };
  const fetchlitigantList = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/litigant/litigant_list`);
      const liti = response.data.map((student, index) => ({
        ...student,
        id: index + 1,
      }));
      setlitigantList(liti);
    } catch (error) {
      console.error("Error fetching student list:", error);
    }
  };
  const fetchadvocateList = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/advocate/advocate_list`);
      const advocate = response.data.map((student, index) => ({
        ...student,
        id: index + 1,
      }));
      setadvocateList(advocate);
    } catch (error) {
      console.error("Error fetching student list:", error);
    }
  };
  const fetchblogList = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/blog/get_blogs`);
      const Blog = response.data.map((student, index) => ({
        ...student,
        id: index + 1,
      }));
      setblogList(Blog);
    } catch (error) {
      console.error("Error fetching student list:", error);
    }
  };

  const fetchjudiciaryList = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/judiciary/Judiciary_list`
      );
      const judiciary = response.data.map((student, index) => ({
        ...student,
        id: index + 1,
      }));
      setjudiciaryList(judiciary);
    } catch (error) {
      console.error("Error fetching student list:", error);
    }
  };

  console.log("advocateList", advocateList);
  // test

  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BACKEND_URL}/test1/addquizzes`, {
        question,
        options,
        correctAnswer,
      });
      if (response.status === 200) {
        // Assuming the response contains the newly added quiz data
        const data = response.data;
        console.log(data);
      } else {
        throw new Error("Failed to add quiz");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [resource_heading, setresource_heading] = useState("");
  const [resource_paragraph, setresource_paragraph] = useState("");
  const [resource_category, setresource_category] = useState("");
  const [resource_url, setresource_url] = useState("");
  const handleSubmitresource = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BACKEND_URL}/resource/add`, {
        resource_heading,
        resource_paragraph,
        resource_category,
        resource_url,
      });
      if (response.status === 200) {
        // Assuming the response contains the newly added quiz data
        const data = response.data;
        console.log(data);
      } else {
        throw new Error("Failed to add quiz");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [resources, setresources] = useState([]);

  const [emailall, setemailall] = useState([]);
  useEffect(() => {
    // Fetch blogs from the API endpoint
    const fetchResource = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/resource/get_resources`
        );
        setresources(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchResource();

    const fetchallemail = async () => {
      try {
        const response = await axios.post(`${BACKEND_URL}/email/getallemail`);
        setemailall(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchallemail();
  }, []);

  const [formData, setFormData] = useState({
    fname: "Admin",
    mname: "Admin",
    lname: "Admin",
    mobile: "",
    email: "",
    whatsapp_no: "",
    blogg_heading: "",
    blogg_paragraph: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  console.log("blog", formData);
  const handleSubmitblog = async () => {
    try {
      const response = await axios.post(`${BACKEND_URL}/blog/add`, formData);
      if (response.status == 201) {
        toast.success("Blog created successfully");
      } else {
        console.error("Blog creation failed:", response.data);
        // Handle error
      }
    } catch (error) {
      console.error("Error creating blog:", error);
      // Handle error
    }
  };

  const logout = () => {
    Cookies.remove("jwt");
    window.location.reload();
  };

  return (
    <>
      <div className="navbar_admin">
        <h3>
          {" "}
          <b>Admin of kataria and Associates</b>
        </h3>
        <Button variant="primary" onClick={logout}>
          {" "}
          logout
        </Button>
      </div>

      <Tabs>
        <TabList>
          <Tab> student</Tab>
          <Tab>faculty</Tab>
          <Tab>Litigants</Tab>
          <Tab>judiciary</Tab>
          <Tab>Advocate</Tab>
          <Tab>Blog</Tab>
          <Tab>resources</Tab>
          <Tab>contact</Tab>
          <Tab>test</Tab>
          <Tab>email</Tab>
          <Tab>setting</Tab>
        </TabList>

        <TabPanel>
          <h2>Student</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle name </th>
                <th>Last Name</th>
                <th> Email</th>
                <th> Mobile</th>
                <th> Whatsapp </th>
                <th> Name of College</th>
                <th> Assistance</th>
                <th> Concern</th>
                <th> document</th>
                <th> Specify Subject notes</th>
                <th> Training Specify Topic</th>
              </tr>
            </thead>
            <tbody>
              {studentList.map((student) => (
                <tr>
                  <td>{student.fname}</td>
                  <td>{student.mname}</td>
                  <td>{student.fname}</td>
                  <td>{student.email}</td>
                  <td>{student.mobile}</td>
                  <td>{student.whatsapp_no}</td>
                  <td>{student.Name_college}</td>
                  <td>{student.assistance}</td>
                  <td>{student.concerned}</td>
                  <td>
                    <img src={`${BACKEND_URL}/uploads/${student.document}`} />
                    <button
                      onClick={() => {
                        window.open(
                          `${BACKEND_URL}/uploads/${student.document}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      {" "}
                      view
                    </button>
                  </td>
                  <td>{student.specify_subject_notes}</td>
                  <td>{student.training_specify_opic}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TabPanel>

        <TabPanel>
          <h2>faculty</h2>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle name </th>
                <th>Last Name</th>
                <th> Email</th>
                <th> Mobile</th>
                <th> Whatsapp </th>
                <th> Name of College</th>
                <th> Assistance</th>
                <th> Concern</th>
                <th> document</th>
                <th> Specify Subject notes</th>
                <th> Training Specify Topic</th>
              </tr>
            </thead>
            <tbody>
              {facultyList.map((faculty) => (
                <tr>
                  <td>{faculty.fname}</td>
                  <td>{faculty.mname}</td>
                  <td>{faculty.fname}</td>
                  <td>{faculty.email}</td>
                  <td>{faculty.mobile}</td>
                  <td>{faculty.whatsapp_no}</td>
                  <td>{faculty.Name_college}</td>
                  <td>{faculty.assistance}</td>
                  <td>{faculty.concerned}</td>
                  <td>
                    <img src={`${BACKEND_URL}/uploads/${faculty.document}`} />
                    <button
                      onClick={() => {
                        window.open(
                          `${BACKEND_URL}/uploads/${faculty.document}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      {" "}
                      view
                    </button>
                  </td>
                  <td>{faculty.specify_subject_notes}</td>
                  <td>{faculty.training_specify_opic}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TabPanel>

        <TabPanel>
          <h2>Litigants</h2>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle name </th>
                <th>Last Name</th>
                <th> Email</th>
                <th> Mobile</th>
                <th> Whatsapp </th>
                <th> Name of College</th>
                <th> Assistance</th>
                <th> Concern</th>
                <th> document</th>
                <th> Specify Subject notes</th>
                <th> Training Specify Topic</th>
              </tr>
            </thead>
            <tbody>
              {litigantList.map((litigant) => (
                <tr>
                  <td>{litigant.fname}</td>
                  <td>{litigant.mname}</td>
                  <td>{litigant.fname}</td>
                  <td>{litigant.email}</td>
                  <td>{litigant.mobile}</td>
                  <td>{litigant.whatsapp_no}</td>
                  <td>{litigant.Name_college}</td>
                  <td>{litigant.assistance}</td>
                  <td>{litigant.concerned}</td>

                  <td>
                    <img src={`${BACKEND_URL}/uploads/${litigant.document}`} />
                    <button
                      onClick={() => {
                        window.open(
                          `${BACKEND_URL}/uploads/${litigant.document}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      {" "}
                      view
                    </button>
                  </td>
                  <td>{litigant.specify_subject_notes}</td>
                  <td>{litigant.training_specify_opic}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TabPanel>

        <TabPanel>
          <h2> judiciary</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle name </th>
                <th>Last Name</th>
                <th> Email</th>
                <th> Mobile</th>
                <th> Whatsapp </th>
                <th> Name of College</th>
                <th> Assistance</th>
                <th> Concern</th>
                <th> document</th>
                <th> Specify Subject notes</th>
                <th> Training Specify Topic</th>
              </tr>
            </thead>
            <tbody>
              {judiciaryList.map((judiciary) => (
                <tr>
                  <td>{judiciary.fname}</td>
                  <td>{judiciary.mname}</td>
                  <td>{judiciary.fname}</td>
                  <td>{judiciary.email}</td>
                  <td>{judiciary.mobile}</td>
                  <td>{judiciary.whatsapp_no}</td>
                  <td>{judiciary.Name_college}</td>
                  <td>{judiciary.assistance}</td>
                  <td>{judiciary.concerned}</td>

                  <td>
                    <img src={`${BACKEND_URL}/uploads/${judiciary.document}`} />
                    <button
                      onClick={() => {
                        window.open(
                          `${BACKEND_URL}/uploads/${judiciary.document}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      {" "}
                      view
                    </button>
                  </td>

                  <td>{judiciary.specify_subject_notes}</td>
                  <td>{judiciary.training_specify_opic}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TabPanel>

        <TabPanel>
          <h2> Advocate </h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle name </th>
                <th>Last Name</th>
                <th> Email</th>
                <th> Mobile</th>
                <th> Whatsapp </th>
                <th> Name of College</th>
                <th> Assistance</th>
                <th> Concern</th>
                <th> document</th>
                <th> Specify Subject notes</th>
                <th> Training Specify Topic</th>
              </tr>
            </thead>
            <tbody>
              {advocateList.map((advocate) => (
                <tr>
                  <td>{advocate.fname}</td>
                  <td>{advocate.mname}</td>
                  <td>{advocate.fname}</td>
                  <td>{advocate.email}</td>
                  <td>{advocate.mobile}</td>
                  <td>{advocate.whatsapp_no}</td>
                  <td>{advocate.Name_college}</td>
                  <td>{advocate.assistance}</td>
                  <td>{advocate.concerned}</td>

                  <td>
                    <img src={`${BACKEND_URL}/uploads/${advocate.document}`} />
                    <button
                      onClick={() => {
                        window.open(
                          `${BACKEND_URL}/uploads/${advocate.document}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      {" "}
                      view
                    </button>
                  </td>

                  <td>{advocate.specify_subject_notes}</td>
                  <td>{advocate.training_specify_opic}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TabPanel>

        <TabPanel>
          <h2> Blog</h2>

          <div className="centerCol">
            <div className="welcomeText">
              <h3> Blogg Page</h3>
              <p>
                {" "}
                <b> Note: </b> Please fill the forms for registration to blogg.
              </p>

              <Container>
                {/* <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">First Name *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="First Name "
                  aria-label="First Name "
                  name='fname'
                  aria-describedby="basic-addon1"
                  value={formData.fname}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Middle Name </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="middle Name "
                  aria-label="First Name "
                  name='mname'
                  aria-describedby="basic-addon1"
                  value={formData.mname}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Last Name *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Last Name "
                  aria-label="Last Name "
                  name='lname'
                  aria-describedby="basic-addon1"
                  value={formData.lname}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Email *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Email "
                  aria-label="Email"
                  name='email'
                  aria-describedby="basic-addon1"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Mobile No. *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Mobile No."
                  aria-label="Mobile No."
                  name='mobile'
                  aria-describedby="basic-addon1"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Whatsapp No. *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Whatsapp No."
                  aria-label="Whatsapp No."
                  name='whatsapp_no'
                  aria-describedby="basic-addon1"
                  value={formData.whatsapp_no}
                  onChange={handleChange}
                />
              </Col>
            </Row>*/}
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">Blogg Heading*</Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      placeholder="Blogg Heading"
                      aria-label="Blogg Heading"
                      name="blogg_heading"
                      aria-describedby="basic-addon1"
                      value={formData.blogg_heading}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">
                      {" "}
                      Blog Paragraph*
                    </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      as="textarea"
                      placeholder="max 500 words"
                      name="blogg_paragraph"
                      style={{ height: "100px" }}
                      value={formData.blogg_paragraph}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}></Col>
                  <Col sm={4}>
                    <Button onClick={handleSubmitblog}> Submit</Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle name </th>
                <th>Last Name</th>
                <th> Email</th>
                <th> Mobile</th>
                <th> Whatsapp </th>
                <th> Blog heading</th>
                <th> blogg paragraph</th>
                <th> action </th>
              </tr>
            </thead>
            <tbody>
              {blogList.map((blog) => (
                <tr>
                  <td>{blog.fname}</td>
                  <td>{blog.mname}</td>
                  <td>{blog.fname}</td>
                  <td>{blog.email}</td>
                  <td>{blog.mobile}</td>
                  <td>{blog.whatsapp_no}</td>
                  <td>{blog.blogg_heading}</td>
                  <td>{blog.blogg_paragraph}</td>
                  <td>
                    {" "}
                    <button
                      style={{
                        background: "green",
                        borderRadius: "4px",
                        color: "white",
                      }}
                    >
                      {" "}
                      Aproved
                    </button>{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TabPanel>

        <TabPanel>
          <h2> resources </h2>
          <Container>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Resource Heading</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder=""
                  aria-label="First Name "
                  name="resource_heading"
                  aria-describedby="basic-addon1"
                  value={resource_heading}
                  onChange={(e) => setresource_heading(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">
                  {" "}
                  Resource Paragraph{" "}
                </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  placeholder=" "
                  aria-label="First Name "
                  name="resource_paragraph"
                  aria-describedby="basic-addon1"
                  value={resource_paragraph}
                  onChange={(e) => setresource_paragraph(e.target.value)}
                />
              </Col>
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Resource category</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder=""
                  aria-label="First Name "
                  name="resource_category"
                  aria-describedby="basic-addon1"
                  value={resource_category}
                  onChange={(e) => setresource_category(e.target.value)}
                />
              </Col>
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Resource url</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder=""
                  aria-label="First Name "
                  name="resource_url"
                  aria-describedby="basic-addon1"
                  value={resource_url}
                  onChange={(e) => setresource_url(e.target.value)}
                />
              </Col>
            </Row>
            <Button onClick={handleSubmitresource}> add </Button>

            <h1> all resources</h1>
            {resources.map((resour) => (
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {" "}
                    {resour.resource_heading}
                  </Accordion.Header>
                  <Accordion.Body>{resour.resource_paragraph}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </Container>
        </TabPanel>

        <TabPanel>
          <h2> contact </h2>
        </TabPanel>

        <TabPanel>
          <h2> test </h2>
          <Container>
            <h3> test one </h3>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">question</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  aria-label="First Name "
                  aria-describedby="basic-addon1"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">option </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  aria-label="First Name "
                  name="mname"
                  aria-describedby="basic-addon1"
                  value="a"
                  onChange={(e) => setOptions(e.target.value)}
                />
                <Form.Control
                  aria-label="First Name "
                  name="mname"
                  aria-describedby="basic-addon1"
                  value="b"
                  onChange={(e) => setOptions(e.target.value)}
                />
                <Form.Control
                  aria-label="First Name "
                  name="mname"
                  aria-describedby="basic-addon1"
                  value="c"
                  onChange={(e) => setOptions(e.target.value)}
                />
                <Form.Control
                  aria-label="First Name "
                  name="mname"
                  aria-describedby="basic-addon1"
                  value="c"
                  onChange={(e) => setOptions(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">correct answer</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  aria-label="Last Name "
                  aria-describedby="basic-addon1"
                  value={correctAnswer}
                  onChange={(e) => setCorrectAnswer(e.target.value)}
                />
              </Col>
            </Row>
            <Button onClick={handleSubmit}> Add</Button>
          </Container>
        </TabPanel>

        <TabPanel>
          <h2> All email </h2>
          <Container>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {emailall.map((allmail) => (
                  <tr>
                    <td>{allmail.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </TabPanel>

        <TabPanel>
          <h2> setting </h2>
        </TabPanel>
      </Tabs>
      <ToastContainer />
    </>
  );
}

export default AdminDashboard;
