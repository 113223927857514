import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import sanjeev from "../images/advsanjeev.jpg"
import { FaLinkedin, FaMailBulk,FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../App.css";
function Krishna() {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <Container>
          <h3 className='center '><span>Our Advocate profile section</span></h3>
        </Container>

        <Container>
          <Row>
            <Col> <img src={sanjeev} alt='werty'  className='m-2 imgcus1' />
              <div className='red12'>
              <ul className='rowflex'>
                  <li className='advs'>
                  <Link to= "https://www.linkedin.com/in/sanjeev-kumar-20716241?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ">
                 <FaLinkedin/></Link>
                  </li>
                  <li className='advs'>
                  <a href= 'mailto:cssanjeevkataria@gmail.com'>
                  <FaMailBulk/></a></li>
                  <li className='advs'><Link to = "https://wa.me/+919650756107?text=Hi+Advocate+Anju+I+am+interested+in+your+services+for+my+case+with+your+company+Kataria+and+Associates+"> <FaWhatsapp/></Link></li>

                
                </ul>
                </div>
                <Col>
             <p> 1. Chamber :</p>
             <h6  className='ttrr12'>Advocate Sanjeev Kumar </h6>
             <h6  className='ttrr12'> Chamber 443, 443A, Civil Wing,Tis Hazari, Delhi -54</h6>
             <h6  className='ttrr12'>Delhi -54 </h6>
             <h6  className='ttrr12'> Mobile/ whatsApp + 91- 9650756107,</h6>
             <h6  className='ttrr12'> Email: cssanjeevkataria@gmail.com,</h6>
               </Col>

              <Col> 
            <p>   2. Office:</p>
<h6 className='ttrr12 '> Kataria and Associates,</h6>
<h6 className='ttrr12 '> 443, 443A, Civil Wing,</h6>
<h6 className='ttrr12 '> Tis Hazari Court</h6>
<h6 className='ttrr12 '> Delhi -54 </h6>
</Col>
             
            </Col>
            <Col className='m-2 il '>
              <h3> <b> Legal Background and Qualifications</b></h3>
              <p>Adv Sanjeev Kumar is an advocate practicing at  Supreme Court, High Court, District Courts and NCLT for past 13 years. He is gold medalist in Bachelor of Laws, from North Eastern Hill University Shillong in year 2003 and also an associate member of ICSI. His core area of practice is Corporate Laws, Insolvency & Bankruptcy Laws, Arbitration and Mediation and Matrimonial Laws. He has also served as Legal Officer in Indian Air Force for a brief period of 10 years. The profil of his experience is as follows:-</p>
              <Container >
            
            <h6 className='ttrr12'>  	ADR, Mediation and arbitration In SAMADHAN, HCs, Mediation Centres of Family and other courts, </h6>
            <h6 className='ttrr12'> 	Seven years as Law Officer in Indian Air Force, </h6>
            <h6 className='ttrr12'> 	NCLT cases of Insolvency, revival, winding up and Bankruptcy </h6>
            <h6 className='ttrr12'> 	Armed Force Tribunal, </h6>
            <h6 className='ttrr12'> 	PIL </h6>
            <h6 className='ttrr12'> 	Pro Bono Work, </h6>
            <h6 className='ttrr12'> 	NCLT </h6>
            <h6 className='ttrr12'> 	Complaints, </h6>
            <h6 className='ttrr12'> 	Recovery, </h6>
            <h6 className='ttrr12'> 	Family Law cases such as maintenance, mutual divorce, child custody, cruelty etc </h6>
            <h6 className='ttrr12'> 	Criminal cases under IPC, Arms Act, Detention laws, </h6>
            <h6 className='ttrr12'> 	Defamation and other Torts, </h6>
            <h6 className='ttrr12'> 	Training young advocates, </h6>
            <h6 className='ttrr12'> 	Supreme Court Cases, </h6>
            <h6 className='ttrr12'> 	District Court Cases, </h6>
            <h6 className='ttrr12'> 	Agreement Drafting, </h6>
            <h6 className='ttrr12'> 	Civil Cases, </h6>
            <h6 className='ttrr12'> 	Property Disputes, </h6>
         
          
          </Container>
            </Col>
          </Row>
          <Container> 
            <Row> 

            </Row>
          </Container>
        </Container>
       
      </div>
    </div>
  )
}

export default Krishna