import React from 'react'

function Disclaimer() {
  return (
    <>
    <div ><h1  className='j'><span>Disclaimer</span></h1></div>
    
      <ul>
        <li>
          <p>
          	By using this website, you agree to the terms of this disclaimer.
          </p>
        </li>
        <li>
          <p>
          	The information in the blogg post is for general informational purposes only and does not constitute legal advice.
          </p>
        </li>
        <li>
          <p>
          	We do not represent clients in all jurisdictions. 
          </p>
        </li>
        <li>
          <p>
          	The information contained in this website is for general information purposes only. The information is provided by [business name] and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
          </p>
        </li>
        <li>
          <p>
          	In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
          </p>
        </li>
        <li>
          <p>
          	Through this website you are able to link to other websites which are not under the control of Kataria and Associates. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
          </p>
        </li>
        <li>
          <p>
          	Every effort is made to keep the website up and running smoothly. However, Kataria and Associates takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control
          </p>
        </li>
        <li>
          <p>
          	The information on this website is for general informational purposes only and does not constitute legal advice . For legal advice, please engage one or more of Advocates on panel of the Kataria and Associates. 
          </p>
        </li>
        <li>
          <p>
          	It is not intended to create an attorney-client relationship.
          </p>
        </li>
        <li>
          <p>
          	While we have attempted to maintain the information on this website as accurately as possible, it may contain errors or omissions. 
          </p>
        </li>
        <li>
          <p>
          	We disclaim any liability for any loss or damage caused by errors or omissions, whether such errors or omissions result from negligence, accident, or any other cause.
          </p>
        </li>
        <li>
          <p>
          	You should not rely on the information on this website as a substitute for legal advice from a qualified attorney licensed in the appropriate jurisdiction. 
          </p>
        </li>
        <li>
          <p>
          	If you have any specific legal questions or concerns, you should consult with an attorney.
          </p>
        </li>
      </ul>
    </>
  )
}

export default Disclaimer