import React from 'react'

function Policies() {
  return (
    <>
    <div ><h1  className='j'><span>Website Policies</span></h1></div>
    <ul>
      <li>
        <p>
        	Laws of India apply.
        </p>
      </li>
      <li>
        <p>
        	In case of Disputes, ADR will be restored to. Place of litigation/ ADR will be New Delhi
        </p>
      </li>
      <li>
        <p>
        	International apply in consonance with Indian laws.
        </p>
      </li>
      <li>
        <p>
        	The website is primarily an information tool and should be treated as such.
        </p>
      </li>
      <li>
        <p>
        	Disclaimers are defined and are as per standards trade practices and law of the land.
        </p>
      </li>
      <li>
        <p>
        	The purpose is not to earn profits as much as to serve the users.
        </p>
      </li>
      <li>
        <p>
        	Rights to contents are reserves as per IPR laws of the country.
        </p>
      </li>
      <li>
        <p>
        	Accuracy is maintained , but not guaranteed and liabilities out of inaccuracy are denied.
        </p>
      </li>
      <li>
        <p>
        	By entering and using the website, the users enter into standard users contract  as per trade practices.
        </p>
      </li>
      <li>
        <p>
        	It is a dynamic website. The content will be updated as soon as possible.
        </p>
      </li>
      <li>
        <p>
        	Rights are reserved to deny or serve any user.
        </p>
      </li>
      <li>
        <p>
        	Disputes will be addressed through mutual consent failing which through ADR as per the law. 
        </p>
      </li>
      <li>
        <p>
        	Other policies as specified from time to time.
        </p>
      </li>
      <li>
        <p>
        	Force Majeure applies.
        </p>
      </li>
   
    </ul>
  
    </>
  )
}

export default Policies