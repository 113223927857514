// BlogModal.js
import HTMLReactParser from 'html-react-parser/lib/index';
import React from 'react';
import Modal from 'react-modal';
import parse from 'html-react-parser';

const BlogDetail = ({ isOpen, onClose, blog }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Blog Modal"
      style={{
        overlay: {
          backgroundColor: 'rgb(239, 233, 223)',
        },
        content: {
          top: '30px',
          left: '0',
          right: '0',
          bottom: '0',
          border: 'none',
          background: 'rgb(239, 233, 223)',
          overflow: 'auto',
        },
      }}
    >   <div className=" blogmodal-content">
      <h2>{blog.blogg_heading}</h2>
      <p>{ HTMLReactParser(blog.blogg_paragraph)}</p>
     
      <button onClick={onClose} className="sub-btn1">Close</button>
      </div>
    </Modal>
  );
};

export default BlogDetail;



