import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Container } from 'react-bootstrap'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


function Tests() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
 
  return (
<> 
<Container> 
<Box sx={{ bgcolor: 'background.paper', width: 1300 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="maxWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Login" {...a11yProps(0)} />
          <Tab label="Signup" {...a11yProps(1)} />
       
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        <Container> 

   
        <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Email *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Email "
                  aria-label="Email"
                  name='email'
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row> 
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">password *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="password "
                  aria-label="Email"
                type='password'
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row> 
            <Row> 
        <Col sm={4}> </Col>
        <Col sm={4}>  <Button> take test</Button>  </Col>
        
         
         </Row>
        
        </Container>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
    <Container> 

    <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">First Name *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="First Name "
                  aria-label="First Name "
                  name='first_name'
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Middle Name </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="middle Name "
                  aria-label="First Name "
                  name='first_name'
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Last Name *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Last Name "
                  aria-label="Last Name "
                  name='first_name'
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>

            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Email *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Email "
                  aria-label="Email"
                  name='email'
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Mobile No. *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Mobile No."
                  aria-label="Mobile No."
                  name='Mobile No.'
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Password *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="password"
                  aria-label="Mobile No."
                type='password'
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row> 
       <Row> 
        <Col sm={4}> </Col>
        <Col sm={4}>  <Button> signup </Button>  </Col>
        
         
         </Row>

    </Container>



        </TabPanel>
       
      </SwipeableViews>
    </Box>


</Container>
<Container> 
  <h3> <b> Notes  : - </b> 
 </h3>
 <p> 1.  On click at SUBMIT, the form will be submitted, evaluated and PDF of the Certificate Will be opened in a separate window for downloading by the user. This data will be stored and reported through email to the webmaster Sanjay Chavre and monthly report to Shri Sanjeev Kataria and the webmaster. 
</p>
<h3> About Author : - </h3> 
<p> Advocate Sanjay Chavre , LLB, BE (Mech), PGDBM has more than 42 years of experience in Life, Law, Governance, Mechanical Engineering, etc.  He specializes in designing and developing laws as well as legal strategies to avoid disputes and also resolutions of disputes through Mediation / Arbitration / litigation like in the Family, Property, IPR, Cyber Crimes ,Corporate, Taxation etc. </p>
</Container>
</>
  )
}

export default Tests