import React from 'react'
import { Link } from "react-router-dom";
import { Button, Container } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';

const Dashboard = () => {



  return (

    <>

      <Container className="mb-1">
        <div className="">
          <div className="">
            <h4> <center>   welcome to kataria and associates</center></h4>
            <p> <center>  <p> Choose your test type </p>
            </center></p>
          </div>
        </div>
        <div className='testcardflex'>

          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> Law for Arbitration in India</center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> Arbitration in India Law </center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz2" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> Hindu Divorce Law </center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz3" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> Hindu Adoption Law </center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz4" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> child Custody Law </center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz5" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> hindu Marriage Act 1955</center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz6" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> Special Marriage</center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz7" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> Maintence Law of india </center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz8" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }}  className='m-3'>
            <Card.Body>
              <Card.Title> <center> Muslim Divorce Law  </center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz9" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
          <Card style={{ width: '20rem' }} className='m-3'>
            <Card.Body>
              <Card.Title> <center> Wills and Probate Law </center> </Card.Title>
              <Card.Text>
                <ul>
                  <li> <b>-</b>Total Twenty-five Questions </li>
                  <li> <b>-</b>No negative Marking. </li>
                  <li> <b>-</b>One mark for every correct answer </li>
                  <li> <b>-</b> PDF Certificate to be issued</li>
                </ul>
              </Card.Text>
              <center>  
              <Link to="/Quiz10" >
                 <Button className='ben-12'> Start </Button>
                </Link>
                 </center>
            </Card.Body>
          </Card>
        </div>
      </Container >





     

    </>
  )
}

export default Dashboard