


import React from 'react';
import { useNavigate } from 'react-router-dom';
import advi from "../../images/advsanjeev.jpg";
import adv2 from "../../images/sanjay.jpg";
import adv3 from "../../images/anju.jpg";
import adv4 from "../../images/rajamani.jpg";

const Card = (props) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (props.link) {
      navigate(props.link);
    }
  };

  return (
    <div className="cardq" onClick={handleCardClick} >
      <img src={props.imgUrl} alt={props.alt || 'Image'} className='adimgc' />
      <div className="card-contentq">
        <h2 className='center'>{props.title}</h2>
        <p>{props.content}</p>
      </div>
    </div>
  );
};

const CardContainer = ({ cards }) => (
  <div className="cards-containerq">
    {cards.map((card) => (
      <Card 
       
        key={card.id}
        title={card.title}
        content={card.content}
        imgUrl={card.imgUrl}
        alt={card.alt}
        link={card.link} // Use "link" instead of "Link" to follow standard naming conventions
      />
    ))}
  </div>
);

function CardSlider() {
  const cardsData = [
    { id: 1, title: 'Adv. Sanjeev Kumar', content: '', imgUrl: advi, alt: 'Image 1', link: '/profile-sanjeev' },
    { id: 2, title: 'Adv. Prithvish Rajamani', content: '', imgUrl: adv4, alt: 'Image 2', link: '/profile-Rajamani' },
    { id: 3, title: 'Adv. Sanjay Chavre', content: '', imgUrl: adv2, link: '/profile-sanjay' },
    { id: 4, title: 'Adv. Anju Arora', content: '', imgUrl: adv3, link: '/profile-anju' },
  ];

  return (
    <div>
      <CardContainer cards={cardsData} />
    </div>
  );
}

export default CardSlider;
