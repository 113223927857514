import React from "react";
import Accordion from 'react-bootstrap/Accordion';


const Faq = () => {
  return (
     <>
      <div className="centerCol " style={{marginBottom:"4rem",}}>
        <div className="welcomeText ">
        <h3><span>Question  & Answers</span></h3>
          <p>
            Frequent Questions asked by clients
          </p>


     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header> Q.1 What help can I get from Kataria and Associates?</Accordion.Header>
        <Accordion.Body>
      <p>Ans. Kataria and Associates is a full fledged integrated law firm. Broadly it helps in all stages of law i.e.
<br/>
	Design of Law,
<br/>
	Amendment in Law,<br/>
	Evolution of Bills and Laws,<br/>
	Impact analysis of laws,<br/>
	Design of Legal policies of entities to minimize disputes,<br/>
	ADR,<br/>
	Litigation. <br/>

For details please refer to the Services page. <br/>
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> Q.2 What services are provided by your Firm:- </Accordion.Header>
        <Accordion.Body>
        <p>Ans. They basically include advise and support for dispute resolution -both pre dispute and post dispute. Such as in :-
          <br/>
	Family laws including Divorce, Judicial Separation, Maintenance, Child Custody,Marriage Registration, Cruelty and Domestics violence Petitions, Stridhana claim and recovery, HUF matters, Wills, Partition and others  <br/>
	Insolvency and Bankruptcy Laws Section 8-10 Applications by operational and financial creditors, default,reviving the company, maximizing assets, CoC and RP / IRP matters , resolution and liquidation etc. <br/>
	Armed Forces Tribunal including services matters, pension, disability pension, promotion,court marshals,  <br/>
	Mediation: from agreement drafting to dispute resolution and execution of mediation settlement agreements.  <br/>
	Public Interest Litigation and Pro bono work, <br/>
	Arbitrators, Mediation and Conciliation, <br/>
	Criminal laws including Bail Law, Custody and Remand Laws <br/>
	Legal Consultancy and opinion, <br/>
	Legal Aid to Prisoners, poor persons, women and senior citizens, <br/>
	Motor Accidents Claims Tribunal, <br/>
	Civil Laws including Recovery, <br/>
	Income and GST Tax Tribunals. <br/>
	Section 138 NI Act Cheque bounce cases,  <br/>
	Consumer Protections Laws and Consumer Complaints, <br/>
	IPR Law including Patent, Design, Trade Marks, <br/>
	Business law<br/>
	Immigration law<br/>
	Personal injury law including Motors and other accidents, <br/>
	Labour Laws including Factory Law, Minimum Wages act etc.<br/>
	Real estate law including RERA, <br/>
	Wills and estates law<br/>
	Competitions Law including unfair trade practices, <br/>
	Environment Law including Water, Air, Sea,Environment , <br/> 
	Service Law including pension, promotion, Disciplinary proceedings , gratuity etc<br/>
	Compliance of various acts,<br/>
	Corporate Advisory Services,<br/>
	FDI and other Agreements drafting,<br/>
	Insurance Claims,<br/>
	Commerce, Exports, WTO, Imports and Customs Laws<br/>
	Extradition and Foreign Affairs Laws,<br/>
	Drafting and Design of Bills and Acts,<br/>
	Evaluation of Bills,<br/>
	Legal Research,<br/>
	Legal Drafting<br/>
	Legal Training and Education,<br/>
	AI Applications in Law,<br/>
	Digitization of Law,<br/>
	International Law Research,<br/>
	Legal Articles,<br/>
	Citation Research<br/>
	Advice to Legal fraternity,<br/>
	Legal Advice to Poor,<br/>
	Legal support to NGO/ Associations / Local Bodies,<br/>
	Legal Support to RWAs<br/>
	Legal Advice to Corporate/ Government / Entities,<br/>
	Miscellaneous Legal Matters  <br/>

</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Q.3 Does your firm offer free of cost services?</Accordion.Header>
        <Accordion.Body>
        <p>Ans. Pro bono services are offered as a matter of route to the destitute and deserving. </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Q.4 Do you have a specialist in Family matters?</Accordion.Header>
        <Accordion.Body>
        <p>Ans. Yes , we have several experts in Family Law. Please refer to Profile of Advocates of our firm on he relevant web page for further details. </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header> Q.5 How to contact ? </Accordion.Header>
        <Accordion.Body>
        <p>Ans. Through website, email, whatsup and social media tools. 
          <br/>
          <br/>

Kataria and Associates  <br/>
443, Civil Wing, <br/>
Tis Hazari Court, <br/>
Delhi -54 <br/>
Mobile/ WhatApp:9650756107 <br/>
Email: cssanjeevkataria@gmail.com  <br/>
<br/>
Coimbatore Representative <br/>

TRAN Legal & Mediation,  <br/>
# 31, Rengas Triyambhava,  <br/>
Kalapatti Main Road, <br/>
Coimbatore - 641 014 <br/>
Mobile 6382 221581 <br/>
Website: www.tranlife.in  <br/>

</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>Q.6 What Courts do you cover?</Accordion.Header>
        <Accordion.Body>
        <p>Ans. All from Supreme Court, High Courts, Sessions Courts , District Courts, Tribunals, Lower Court and all other Courts.  </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </div>
      </div>

    </>
   
  );
};

export default Faq;
