import React from 'react';
import { AiFillCaretRight } from "react-icons/ai";
import { FaHandPointRight ,FaLongArrowAltRight } from "react-icons/fa";
// import { AiOutlineMail } from "react-icons/ai";
// import { MdLocationOn } from "react-icons/md";

function Service() {
  return (
    
    <>
     <div className="centerCol">
      <div className="welcomeText">
        <h3>Services Offered by Kataria and Associates</h3>
       <p> Law Firm <b>Kataria and Associates</b>  is a full-service law firm located in Delhi. We have been providing legal services to our clients for over 12 years. Our team of experienced attorneys is dedicated to providing our clients with the highest quality legal representation, help, opinion, advice and support.</p>
       
       <h3> Services</h3> 

       <p><FaHandPointRight/> We offer a wide range of legal services, including:</p> 
       <ul className="unorder_list"> 
        <li> <AiFillCaretRight/> 	Family laws including Divorce, Judicial Separation, Maintenance, Child Custody,Marriage Registration, Cruelty and Domestics violence Petitions, Stridhana claim and recovery, HUF matters, Wills, Partition and others </li>
        <li> <AiFillCaretRight/>	Insolvency and Bankruptcy Laws Section 8-10 Applications by operational and financial creditors, default,reviving the company, maximizing assets, CoC and RP / IRP matters , resolution and liquidation etc.   </li>
        <li> <AiFillCaretRight/>	Armed Forces Tribunal including services matters, pension, disability pension, promotion,court marshals,    </li>
        <li> <AiFillCaretRight/>	Mediation: from agreement drafting to dispute resolution and execution of mediation settlement agreements. </li>
        <li> <AiFillCaretRight/>	Public Interest Litigation and Pro bono work,</li>
        <li> <AiFillCaretRight/> 	Arbitrators, Mediation and Conciliation,</li>
        <li><AiFillCaretRight/>	Criminal laws including Bail Law, Custody and Remand Laws</li>
        <li><AiFillCaretRight/> 	Legal Consultancy and opinion, </li>
        <li><AiFillCaretRight/> 	Legal Aid to Prisoners, poor persons, women and senior citizens,</li>
        <li><AiFillCaretRight/> 	Motor Accidents Claims Tribunal, </li>
        <li><AiFillCaretRight/>	Civil Laws including Recovery, </li>
        <li><AiFillCaretRight/>	Income and GST Tax Tribunals. </li>
        <li> <AiFillCaretRight/>	Section 138 NI Act Cheque bounce cases,  </li>
        <li><AiFillCaretRight/> 	Consumer Protections Laws and Consumer Complaints,  </li>
        <li><AiFillCaretRight/> 	IPR Law including Patent, Design, Trade Marks, </li>
        <li><AiFillCaretRight/> 	Business law </li>
        <li><AiFillCaretRight/>	Immigration law </li>
        <li><AiFillCaretRight/> 	Personal injury law including Motors and other accidents, </li>
        <li> <AiFillCaretRight/>	Labour Laws including Factory Law, Minimum Wages act etc.</li>
        <li><AiFillCaretRight/>	Real estate law including RERA,  </li>
        <li><AiFillCaretRight/> 	Wills and estates law </li>
        <li><AiFillCaretRight/>	Competitions Law including unfair trade practices,  </li>
        <li><AiFillCaretRight/> 	Environment Law including Water, Air, Sea,Environment ,   </li>
        <li><AiFillCaretRight/>	Service Law including pension, promotion, Disciplinary proceedings , gratuity etc  </li>
        <li><AiFillCaretRight/> FDI and other Agreements drafting, </li>
        <li><AiFillCaretRight/>	Compliance of various acts, </li>
        <li><AiFillCaretRight/>	Corporate Advisory Services, </li>
        <li><AiFillCaretRight/>	FDI and other Agreements drafting, </li>
        <li><AiFillCaretRight/> 	Insurance Claims,</li>
        <li><AiFillCaretRight/>	Commerce, Exports, WTO, Imports and Customs Laws </li>
        <li><AiFillCaretRight/>	Extradition and Foreign Affairs Laws, </li>
        <li><AiFillCaretRight/>	Drafting and Design of Bills and Acts,  </li>
        <li><AiFillCaretRight/>	Evaluation of Bills, </li>
        <li><AiFillCaretRight/>	Legal Research, </li>
        <li><AiFillCaretRight/>	Legal Drafting </li>

        <li><AiFillCaretRight/> Legal Training and Education, </li>
        {/* <li><AiFillCaretRight/> Legal Consultancy, </li> */}
        <li><AiFillCaretRight/> AI Applications in Law, </li>
        <li><AiFillCaretRight/> Digitization of Law, </li>
        {/* <li><AiFillCaretRight/> Bail Law, </li> */}
        {/* <li><AiFillCaretRight/> Custody and Remand Law, </li> */}
        <li><AiFillCaretRight/> International Law Research, </li>
        <li><AiFillCaretRight/> Legal Articles, </li><AiFillCaretRight/>
        <li><AiFillCaretRight/> Citation Research </li>
        <li><AiFillCaretRight/> Advice to Legal fraternity, </li>
        <li><AiFillCaretRight/> Legal Advice to Poor, </li>
        {/* <li><AiFillCaretRight/>Pro bono Work  </li> */}
        <li><AiFillCaretRight/> Legal support to NGO/ Associations / Local Bodies, </li>
        <li><AiFillCaretRight/>Legal Support to RWAs  </li>
        <li><AiFillCaretRight/>Legal Advice to Corporate/ Government / Entities,  </li>
        <li><AiFillCaretRight/> Miscellaneous Legal Matters  </li>
       </ul>

       <h6 className="bold"> <FaLongArrowAltRight/> Many many landmark cases were handled by us.  </h6>
       <h6 className="bold">  <FaLongArrowAltRight/>We understand that legal matters can be complex and stressful. That's why we are committed to providing our clients with personalized attention and guidance throughout the legal process. We will work with you to understand your needs and goals, and develop a legal strategy that is tailored to your individual situation.</h6>

       <h6 className="bold"><FaLongArrowAltRight/>
We are also committed to making our legal services affordable for all. We offer a free consultation so that you can learn more about your legal options and the costs associated with pursuing them.
</h6>
<h6 className="bold"> <FaLongArrowAltRight/> If you are facing a legal issue, we encourage you to contact us today. We would be honored to help you achieve your legal goals.</h6>

      </div>



    </div>
    </>
  )
}

export default Service