import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {BACKEND_URL} from "../services/helper"

function SiteMap() { 

  const [formData, setFormData] = useState({
    fname:"",
    mname:"",
    lname:"",
    mobile:"",
    email:"",
    whatsapp_no:"",
    Designation:"",
    Name_college:"",
    question:"",
    document:"",
    specify_subject_notes:"",
    training_specify_opic:"",
    research:"",
    support:"",
    concerned:"" ,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      document: e.target.files[0],
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataForApi = new FormData();
      for (const key in formData) {
        formDataForApi.append(key, formData[key]);
      }

      const response = await axios.post(`${BACKEND_URL}/advocate/advocate_submit`, formDataForApi);

      console.log(response.data); // Handle the response as needed
      toast.success("submitted successfully");
      // Optionally, you can redirect to another page or show a success message
    } catch (error) {
      console.error(error);
      // Handle errors, show an error message, etc.
    }
  };
console.log("formData",formData)
  return (
    
  
      <>

<div className="centerCol">
        <div className="welcomeText">

          <h3> Advocate  Page</h3>
          <p> <b>  Note: </b>Please fill the forms for invoking advice.</p>

          <Container>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> First Name</b>*</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="First Name "
                  aria-label="First Name "
                  name='fname'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Middle Name</b> </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="middle Name "
                  aria-label="First Name "
                  name='mname'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">  <b> Last Name </b>*</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Last Name "
                  aria-label="Last Name "
                  name='lname'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Email</b> *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Email "
                  aria-label="Email"
                  name='email'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b>Mobile No. </b> *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Mobile No."
                  aria-label="Mobile No."
                  name='mobile'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"><b> Whatsapp No.</b> *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Whatsapp No."
                  aria-label="Whatsapp No."
                  name='whatsapp_no'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"><b>Designation</b> *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Designation"
                  aria-label="Designation"
                  name='Designation'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>


           
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"><b> Name/s of the Court/s practicing, Place</b>*</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder=" Name/s of the Court/s practicing"
                  aria-label=" Name/s of the Court/s practicing"
                  name='Name_college'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
      
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Question /s</b> (max 500 words)
                  (Per Question Rupees  one thousand. Answer within a week. For next day answers double the payment. ) </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  style={{ height: '100px' }}
                  onChange={handleInputChange}
                  name='question'
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Do you need legal consultation / advice , then specify the details in max 500 words or attach MS Word file.</b>   (Per consultation Rs one thousand) </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  type="file" 
                  onChange={handleFileChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b>  Do you need legal research / any other assistance then specify subject/s </b> (per research Rs Eleven  thousand.) </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  name='research'
                  style={{ height: '100px' }}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Do you need drafting , then specify the draft with details </b>(per draft Rs one thousand) </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  name="specify_subject_notes"
                  style={{ height: '100px' }}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Do you need training/s then specify topic/s </b> (per training Rs one thousand) </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  style={{ height: '100px' }}
                  name='training_specify_opic'
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b>  Do you need support for designing a law </b> (per law Rs Eleven Thousand) </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  style={{ height: '100px' }}
                  name='support'
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Name of the Act concerned	</b>  </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Name of the Act concerned"
                  aria-label="Name of the Act concerned"
                  name='concerned'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>

              </Col>
              <Col sm={4}>
                <Button onClick={handleSubmit}> Save </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <ToastContainer />
    </>
  )
}

export default SiteMap