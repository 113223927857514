import React from "react";

import { Button, Container } from 'react-bootstrap'
// import LocomotiveScroll from 'locomotive-scroll';
import ab1 from "../images/ab1.png"
import ab2 from "../images/ab2.png"
import ksgroup from "../images/ksgroup.jpg"


const About = () => {
  
  return (
    <>

      <Container className="mb-1">
        <div className="displayflex1">
          <div className="about_c_ss box_hom2">
            <img src={ksgroup} alt="img"/>
          </div>
          <div className="about_c_ss box_homxx">
            <div className="textxx">
              <h4 className="about_font"> <center> Our Journey, Your Legal Destination</center></h4>
              <p> <center>  Law Firm Kataria and Associates is a full-service law firm located in Delhi. We have been providing legal services to our clients for over 12 years. Our team of experienced attorneys is dedicated to providing our clients with the highest quality legal representation..
              </center></p>
            </div>
          </div>
        </div>
      </Container>


      {/* <Container > */}
        <div className="krish">
          <div className="about_c_ss">
            <div className="text_item">
           <center><h1>Your Legal Allies</h1></center>
           <center><p className="p-4"> We believe in making your legal journey as easy as possible, offering you the support and advice you need. Our goal is to be your legal allies, making sure you understand your rights and get the help you deserve. On our website, you can learn more about our team, the types of legal help we offer, and how we can be a part of your legal success.</p></center> 
            </div>
          </div>
          <div className="about_c_ss box_hom21">
            <img src={ab2} alt="img"/>

          </div>
        </div>
      {/* </Container> */}

      <Container className="about_upper">
        <div className="ftflex">
          <div className="about_c_ssxx ">
            <img src={ab1} alt="image"></img>
          </div>

          <div className="about_c_ssxx myyl about-margin">
          <div className='about_c_ssxx '>
            <h4> <center> <b>Why choose us..!</b> </center> </h4>
            <div className="ds">
              <ul className="lh">
                
                  {/* <li>
                  ⟫ Delivering Results, Not Just Services
                  </li> */}
                  <li>
                  ⟫ High-Client-Centric Approach
                  </li>
                  <li>

                  ⟫ Personalized Legal Solutions
                  </li>
                  <li>
                  ⟫ Affordable Legal Services
                  </li>
                  <li>
                  ⟫ Flexible Meeting Options                </li>
                
                  {/* <li>⟫ Our Dedication to Your Legal Success </li> */}
                  <li>
                  ⟫ Proven Track Record                </li>
                  <li>

                  ⟫ Clear Communication                </li>
                  <li>
                  ⟫ Case-Specific Legal Team                </li>
                  <li>
                  ⟫ Educational Resources              </li>
                
              </ul>
            </div>
            <center> <a href="./Service"><Button className="btns"> For More Information »</Button></a> </center>
          </div>
          </div>
          



        </div>

      </Container>
    </>
  );
};

export default About;
