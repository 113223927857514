import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {BACKEND_URL} from "../services/helper"


function Litigants() { 
  const [formData, setFormData] = useState({
    fname: '',
    mname: '',
    lname: '',
    mobile: '',
    email: '',
    whatsapp_no: '',
    name_of_parents: '',
    address: '',
    age: '',
    question: '',
    document: '',
    specify_subject_notes: '',
    concerned: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      document: e.target.files[0],
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataForApi = new FormData();
      for (const key in formData) {
        formDataForApi.append(key, formData[key]);
      }

      const response = await axios.post(`${BACKEND_URL}/litigant/litigant_submit`, formDataForApi);

      console.log(response.data); // Handle the response as needed
      toast.success("submitted successfully");
      // Optionally, you can redirect to another page or show a success message
    } catch (error) {
      console.error(error);
      // Handle errors, show an error message, etc.
    }
  };


  return (
    <>

      <div className="centerCol">
        <div className="welcomeText">

          <h3> Litigant Page</h3>
          <p> <b>  Note: </b>Please fill the forms for invoking advice.</p>

          <Container>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">First Name *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="First Name "
                  aria-label="First Name "
                  name='fname'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Middle Name </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="middle Name "
                  aria-label="First Name "
                  name='mname'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Last Name *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Last Name "
                  aria-label="Last Name "
                  name='lname'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Email *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Email "
                  aria-label="Email"
                  name='email'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Mobile No. *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Mobile No."
                  aria-label="Mobile No."
                  name='mobile'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Whatsapp No. *</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Whatsapp No."
                  aria-label="Whatsapp No."
                  name='whatsapp_no'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>


            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Name of the Father / Mother*</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Name of the Father / Mother"
                  aria-label="Name of the Father / Mother"
                  name='name_of_parents'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Address*</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Address"
                  aria-label="Address"
                  name='address'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url">Age*</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Age"
                  aria-label="Age"
                  name='age'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>


            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Question /s</b>
                   </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  style={{ height: '100px' }}
                  name='question'
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b>  Attach file/s </b>  </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  type="file" 
                  onChange={handleFileChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Do you need  any other assistance, please specify </b>   </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  style={{ height: '100px' }}
                  name='specify_subject_notes'
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>
                <Form.Label htmlFor="basic-url"> <b> Name of the Act concerned, if your know</b>  </Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder="Name of the Act concerned"
                  aria-label="Name of the Act concerned"
                  name='concerned'
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center m-2">
              <Col sm={5}>

              </Col>
              <Col sm={4}>
                <Button onClick={handleSubmit}>  Save </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Litigants