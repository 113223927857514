import React from 'react'
import m1 from "../images/media/md1.jpg"
import m2 from "../images/media/md2.jpg"
import m3 from "../images/media/md3.jpg"
import m4 from "../images/media/md4.jpg"
import m5 from "../images/media/md5.jpg"
import m6 from "../images/media/md6.jpg"
import m7 from "../images/media/md7.jpg"
import m8 from "../images/media/md8.jpg"
import m9 from "../images/media/md9.jpg"
import m10 from "../images/media/md10.jpg"
import m11 from "../images/media/md11.jpg"
import m12 from "../images/media/md12.jpg"
import m13 from "../images/media/md13.jpg"
import m14 from "../images/media/md14.jpg"
import m15 from "../images/media/md15.jpg"
import m16 from "../images/media/md16.jpg"
import m17 from "../images/media/md17.jpg"
import m18 from "../images/media/md18.jpg"
import m19 from "../images/media/md19.jpg"


function Advocate() {
  return ( 

    <>
 

<div class="containerxx">
<img src={m1} alt='#'/> 
 <img src={m2} alt='#'/>
  <img src={m3} alt='#'/>
  <img src={m4} alt='#'/>
  <img src={m5} alt='#'/>
  <img src={m6} alt='#'/>
  <img src={m7} alt='#'/>
  <img src={m8} alt='#'/>
  <img src={m9} alt='#'/>
  <img src={m10} alt='#'/>
  <img src={m11} alt='#'/>
  <img src={m12} alt='#'/>
  <img src={m13} alt='#'/>
  <img src={m14} alt='#'/>
  <img src={m15} alt='#'/>
  <img src={m16} alt='#'/>
  <img src={m17} alt='#'/>
  <img src={m18} alt='#'/>
  <img src={m19} alt='#'/>

</div>
      {/* </div> */}
  

    </>
  )
}

export default Advocate