import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import advi from "../images/adv1.jpg"
import { FaLinkedin, FaMailBulk,FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../App.css";


function Sanjay() {
  return (
    <div className="centerCol">
      <div className="welcomeText">
        <Container>
          <h3 className='center '><span>Our Advocate profile section</span></h3>
        </Container>

        <Container>
          <Row>
            <Col> <img src={advi} alt='werty'  className='m-2 imgcus1' />
              <div className='red12'>
                            <ul className='rowflex'>
                  <li className='advs'>
                  <Link to= "https://www.linkedin.com/in/sanjay-chavre-095b2752/ ">
                 <FaLinkedin/></Link>
                  </li>
                  <li className='advs'>
                  <a href= 'mailto:sanjay.chavre@gmail.com'>
                  <FaMailBulk/></a></li>
                  <li className='advs'><Link to = "https://wa.me/+919958985634?text=Hi+Advocate+Anju+I+am+interested+in+your+services+for+my+case+with+your+company+Kataria+and+Associates+"> <FaWhatsapp/></Link></li>

                
                </ul>
              </div>
              <Col>
             <p> 1. Chamber :</p>
             <h6  className='ttrr12'>Advocate Sanjay Chavre </h6>
             <h6  className='ttrr12'> 443, 443A, Civil Wing, Tis Hazari CourtDelhi 110 054</h6>
             <h6  className='ttrr12'>Delhi -54 </h6>
             <h6  className='ttrr12'> Mobile and what-app no. 9958985634,</h6>
             <h6  className='ttrr12'> sanjay.chavre@gmail.com </h6>
            </Col>
            
            <Col> 
            <p>   2. Office:</p>
<h6 className='ttrr12 '> Kataria and Associates,</h6>
<h6 className='ttrr12 '> 443, 443A, Civil Wing,</h6>
<h6 className='ttrr12 '> Tis Hazari Court</h6>
<h6 className='ttrr12 '> Delhi -54 </h6>
</Col>
            </Col>
            <Col className='m-2 il '>
              <h3> <b> Legal Background and Qualifications</b></h3>
              <h5>Mr. Chavre had done LLB  from Maharishi Law School ,Maharishi University of Information Technology,   NOIDA. He is also a Mechanical Engineer with Post Graduate Diploma in Business Management equivalent to MBA.  The profil of his experience is as follows:-</h5>
              <br></br><h5>
              Mr. Chavre has more than 39 years of work experience in the Government and private sector mostly in technology/ industrial and its infrastructure development and promotion. He has been focal in raising institutions of industrial infrastructure and technology development in diverse fields like locks, stones, machine tools, automobiles and components including EV, tool rooms, Environment management facilities, quality & productivity improvement, manufacturing technology, Industry 4.0 etc.
              </h5>
              <br></br>
              <h5>
              He retired in Aug 2020 as Senior Development Officer for heavy engineering industry in Ministry of Heavy Industry, Govt of India. Previously he was in charge of investment promotion, infrastructure development and international cooperation between Europe and India in the Ministry of Industry and Commerce. He was involved in organizing a large number of programmes, projects and events both at enterprise level as well as Ministerial level. He was instrumental in creating a number of technology development institutions at national and international level. He also represented the Government of India in bilateral and multi lateral negotiations in the investment promotion and heavy industry development matters. He is widely travelled in the World.
              </h5>
              <Container >
            <h2 className='ttrr12'> a.	ADR including Arbitration, Mediation, Conciliation, Lok Adalat  </h2>
            <h2 className='ttrr12'>  a.	ADR including Arbitration, Mediation, Conciliation, Lok Adalat  </h2>
            <h2 className='ttrr12'>  c.	Business Law </h2>
            <h2 className='ttrr12'>  d.	Civil and Criminal Laws, </h2>
            <h6 className='ttrr12'> e.	Company Law, </h6>
            <h6 className='ttrr12'>  f.	Corporate Law, </h6>
            <h6 className='ttrr12'>  g.	Criminal, Bail, Remand </h6>
            <h6 className='ttrr12'> h.	Customs, Commerce, Exports, Imports, </h6>
            <h6 className='ttrr12'>  i.	Digitization of Law, </h6>
            <h6 className='ttrr12'> j.	Family Law, Divorce, Custody, Maintenance, 498A IPC </h6>
            <h6 className='ttrr12'>   k.	IB Act,  </h6>
            <h6 className='ttrr12'> l.	International Law   </h6>
            <h6 className='ttrr12'>   m.	IPR including Patent, Copyright, Trademarks, GI, Design  </h6>
            <h6 className='ttrr12'>  n.	Legal Drafting, Writing and Legal Papers,  </h6>
            <h6 className='ttrr12'>  o.	Legal Research,  </h6>
            <h6 className='ttrr12'>  p.	PIL,  </h6>
            <h6 className='ttrr12'> q.	Property Law   </h6>
            <h6 className='ttrr12'>  r.	Recovery and Claims ,  </h6>
            <h6 className='ttrr12'>  s.	Taxation,   </h6>
            <h6 className='ttrr12'>  t.	Writing Articles,  </h6>

          </Container>
            </Col>
          </Row>
         
        </Container>
       
      </div>
    </div>
  )
}

export default Sanjay