import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import jsPDF from 'jspdf';
import sanjeevsign from "../images/sanjeevsign.png"
import prithvisign from "../images/prithvisign.png"
import newimg3 from "../images/newimg3.png"
import striptags from 'striptags'
import html2canvas from 'html2canvas';
import "./kmss.css"
// import Certificate from './Certificate'; 
import {BACKEND_URL} from "../services/helper"


function Quiz() {
  const [loader, setLoader] = useState(false);
    const [quizData, setQuizData] = useState([]);
    const [userAnswers, setUserAnswers] = useState(Array(quizData.length).fill(''));
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(1200); // 20 minutes in seconds
    const values = [true];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
  
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }
    useEffect(() => {
        // Fetch quiz data from the backend
        axios.get(`${BACKEND_URL}/test1/quizzes`) // Assuming you added /getQuiz route to fetch the entire quiz data
            .then((response) => {
                setQuizData(response.data);
                setUserAnswers(Array(response.data.length).fill(''));
            })
            .catch((error) => console.error('Error fetching quiz data:', error));
    }, []);

    const handleNextQuestion = () => {
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    };

    const handlePrevQuestion = () => {
        setCurrentQuestion((prevQuestion) => Math.max(0, prevQuestion - 1));
    };

    const handleSubmit = () => {
        // Submit user answers to the backend
        axios.post(`${BACKEND_URL}/test1/submitQuiz`, { answers: userAnswers })
            .then((response) => { 
                setScore(response.data.score);
                if (response.data.score > 0) {
                    handleShow(true)
                }
              })
          
            .catch((error) => console.error('Error submitting quiz:', error));
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining((prevTime) => Math.max(0, prevTime - 1));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    if (timeRemaining === 0) {
        handleSubmit(); // Automatically submit quiz when time runs out
    }


    const  testusername = localStorage.getItem("username");
    const  testuseremail = localStorage.getItem("useremail");
    console.log(testusername)
    console.log(testuseremail) 


    const handleGeneratePDF = () => {
      const content = document.getElementById('certificate');
  
      html2canvas(content)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 210;
          const textX = 80;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          pdf.text("Kataria & Associates", textX, 50, { lineHeight: 10 }); // Adjust lineHeight as needed for the top distance
    pdf.text(` Ms/Mr${testusername}`, textX, 60, { lineHeight: 20 }); 
    // pdf.text(` E-mail ${testuseremail}`, textX, 70, { lineHeight: 20 }); 
    // pdf.text("For participation in the online test on the subject of Law for Arbitration in India", textX, 80, { lineHeight: 30 }); 
    // pdf.text(`She/he has scored ${score} out of 25.`, textX, 90, { lineHeight: 40 }); 
    // pdf.text("Congratulations", textX, 60, { lineHeight: 50 }); 
    // pdf.text("Sanjeev Kumar", textX, 60, { lineHeight: 60 }); 
    // pdf.text("Advocate", textX, 60, { lineHeight: 70 }); 
    // pdf.text("Prithvish Rajamani", textX, 60, { lineHeight: 70 }); 
    // pdf.text("Advocate", textX, 60, { lineHeight: 70 }); 
    // pdf.text("Kataria & Associates", textX, 60, { lineHeight: 70 }); 
    // pdf.text("443, 443A, Civil Wing, Tis Hazari Courts, Delhi 110054", textX, 60, { lineHeight: 70 }); 
    // pdf.text("Whatsapp. 96507 56107, E-mail: cssanjeevkataria@gmail.com", textX, 60, { lineHeight: 70 }); 
    // pdf.text("Website: katariaandassociates.in", textX, 60, { lineHeight: 70 }); 
        })
        .catch((error) => {
          console.error('Error generating PDF:', error);
        });
    };

    
    const generatePDF = () => {
      const doc = new jsPDF();
      // Define your JSX content with inline CSS
      const content = `
      <div style=" fontFamily:Arial, sans-serif,marginLeft: 150px, padding: 3rem, backgroundColor: #ffffff, color: #000000 ">
      <div className="certificate" style={{ maxHeight: 1000px, maxWidth: 1000px, margin: 50px auto, padding: 80px, border: 2px solid #000000, , backgroundPosition: center, backgroundSize: cover, backgroundRepeat: no-repeat, backgroundSize: 50%, backgroundColor: #28282818, borderRadius: 10px, textAlign: center , boxShadow: 0 2px 4px rgba(0, 0, 0, 0.1)>
        <div className="certificate-header" style={{ fontSize: '30px', fontWeight: 'bold' }}>Kataria & Associates</div>
        <div className="certificate-header" style={{ fontSize: '30px', fontWeight: 'bold' }}>Present a Certificate of Participation to</div>
        <div className="participant-name" style={{ fontSize: '20px', marginTop: '20px' }}>Ms/Mr  <b> {testusername} </b>  </div>
        <div className="participant-email" style={{ fontSize: '16px', marginTop: '10px' }}>E-mail : <b> {testuseremail} </b> </div>
        <div className="test-subject" style={{ fontSize: '18px', marginTop: '20px' }}>For participation in the online test on the subject of <b> Law for Arbitration in India</b> </div>
        <div className="score" style={{ fontSize: '18px', marginTop: '20px' }}>She/he has scored <b>  {score}</b>  out of 25.</div>
        <div className="congratulations" style={{ fontSize: '32px', marginTop: '20px', fontStyle: 'italic' }}>Congratulations</div>
      
        <div className="signatures" style={{ display: 'flex', justifyContent: 'space-between' }}>
        
          <div className="signature" style={{ flexBasis: '35%', fontSize: '16px' }}>
            <img src= {sanjeevsign}  alt ="Signature" style={{ maxWidth: '50%', height: '5rem' }} />
            <br /><br />
            <p style={{ textAlign: 'center', marginTop: '-1.5rem', fontStyle: 'italic' }}>
            Sanjeev Kumar<br />
            Advocate
            </p>
          </div>
        
          <div className="signature" style={{ flexBasis: '35%', fontSize: '16px' }}>
            <img src={prithvisign} alt="Signature" style={{ maxWidth: '40%', height: '6.2rem' , transform: 'rotate(43deg)' }} />
            <br /><br />
            <p style={{ textAlign: 'center', marginTop: '-3rem', fontStyle: 'italic' }}>
            Prithvish Rajamani<br />
            Advocate
            </p>
          </div>
        
        </div>
      
        <div className="firm-details" style={{ marginTop: '30px', fontSize: '16px' }}>
          Kataria & Associates<br />
          443, 443A, Civil Wing, Tis Hazari Courts, Delhi 110054<br />
          Whatsapp. 96507 56107, E-mail: cssanjeevkataria@gmail.com<br />
          Website: <a href="http://katariaandassociates.in">katariaandassociates.in</a>
        </div>
      
      </div>
    </div>

      
      `; 
      const certificateContent = [
        "Certıfıcate",
        "Kataria & Associates present a certificate of Participation to",
        `Ms/ Mr ${testusername}`,
        `E-mail ${testuseremail} `,
        "`for participation in the online test on the subject of Law for Arbitration in India `",
        `She/he has scored ${score} out of 25.`,
        "Congratulations",
        "Kataria & Associates",
        "443, 443A, Civil Wing, Tis Hazari Courts, Delhi 110054",
        "Whatsapp. 96507 56107, E-mail: cssanjeevkataria@gmail.com",
        "Website: katariaandassociates.in"
    ];
    
    // Set font size and color
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Black color
    
    // Calculate the center of the document
    const centerX = doc.internal.pageSize.getWidth() / 2;
    
    // Add certificate content to the PDF document
    certificateContent.forEach((line, index) => {
        const textWidth = doc.getTextWidth(line); // Get the width of the text
        const textX = centerX - textWidth / 2; // Calculate the x-coordinate to center-align the text
        const textY = 10 + index * 10; // Adjust the y-coordinate based on the line index
        doc.text(textX, textY, line);
    });
    
    // Set font size and color for advocates' names
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Black color
    
    // Add advocates' names to the right and left corners
    doc.text(150, 80, " Advocate Sanjeev Kumar"); // Adjusted for right corner
    doc.text(10, 80, "Senior Advocate Prithvesh Rajamani"); // Adjusted for left corner
    

      doc.save('generated_pdf.pdf');
    };

    //   const paragraph = document.querySelector('.certificate-header');
    //   if (paragraph) {
    //     doc.setFontSize(12);
    //     doc.setTextColor(0, 0, 255); // Blue color
    //     doc.text(80, 80, paragraph.textContent);
    //   } 
    //   const paragraph1 = document.querySelector('.participant-name');
    //   if (paragraph1) {
    //     doc.setFontSize(12);
    //     doc.setTextColor(0, 0, 255); // Blue color
    //     doc.text(100, 80, paragraph1.textContent);
    //   } 
    //   const paragraph2 = document.querySelector('.score');
    //   if (paragraph2) {
    //     doc.setFontSize(12);
    //     doc.setTextColor(50, 70, 255); // Blue color
    //     doc.text(50, 80, paragraph2.textContent);
    //   } 

    //   const heading = document.querySelector('.participant-email');
    //   if (heading) {
    //     doc.setFontSize(18);
    //     doc.setTextColor(255, 50, 70); // Red color
    //     doc.text(110, 70, heading.textContent);
    //   }
    
      
    //   // Strip HTML tags from the content
    //   const strippedContent = striptags(content);
    
    //   // Set font size and color
    //   doc.setFontSize(20);
    //   doc.setTextColor(255, 0, 0); // Red color
    
    //   // Add the content to the PDF
    //   // You may need to adjust the coordinates (x, y) according to your layout
    //   doc.text(strippedContent, 10, 10);
    
    //   // Save the PDF
    //   doc.save('generated_pdf.pdf');
    // };

    // const downloadPDF = () =>{
    //   const capture = document.querySelector('.actual-receipt');
    //   setLoader(true);
    //   html2canvas(capture).then((canvas)=>{
    //     const imgData = canvas.toDataURL('img/png');
    //     const doc = new jsPDF('p', 'mm', 'a4');
    //     const componentWidth = doc.internal.pageSize.getWidth();
    //     const componentHeight = doc.internal.pageSize.getHeight();
    //     doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
    //     setLoader(false);
    //     doc.save('receipt.pdf');
    
    


    
  
    return (
        <div className='test_all_itemjs'>
            <p className="heading-txt">Kataria test</p>
            <div className="containerg">

                <p>Time remaining: {formatTime(timeRemaining)}</p>
                {quizData.length > 0 && (
                    <>
                        <div className="question ">
                            <span id="question-number">{currentQuestion + 1}. </span>
                            <span id="question-txt"> <p>{quizData[currentQuestion].question}</p></span>
                        </div>
                        <div className="option-container ">
                            <ul>
                                {quizData[currentQuestion].options.map((option, optionIndex) => (
                                    <li key={optionIndex}>
                                        <input
                                            type="radio"
                                            value={option}
                                            checked={userAnswers[currentQuestion] === option}
                                            onChange={() => {
                                                const updatedAnswers = [...userAnswers];
                                                updatedAnswers[currentQuestion] = option;
                                                setUserAnswers(updatedAnswers);
                                            }}
                                        />
                                        {option}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='test_button'>

                            <Button variant="primary" onClick={handlePrevQuestion} disabled={currentQuestion === 0}>prev</Button>{' '}
                            <Button variant="success" onClick={handleSubmit}>submit</Button>{' '}
                            <Button  variant="primary" onClick={handleNextQuestion} disabled={currentQuestion === quizData.length - 1} >next</Button>{' '}
                        </div>


                        {score !== null && <p>Your score: {score}</p>}
                    </>)}

            </div>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header >
          <Modal.Title>Thank you for submit your test </Modal.Title>
        </Modal.Header>
        <Modal.Body>   



        <Table  bordered>
      <thead>
        <tr>
          <th> Name</th>
          <th> {testusername}</th>
        </tr> 
           <tr>
          <th>Email</th>
          <th> {testuseremail}</th>
        </tr> 
           <tr>
          <th>Score</th>
          <th>  {score !== null && <p>Your score: {score}</p>} </th>
        </tr> 
          
      </thead> 

      <tbody> 

        
      <tr> 
        <div style={{ fontFamily: 'Arial, sans-serif',marginLeft: '150px', padding: '3rem', backgroundColor: '#ffffff', color: '#000000' }}>
      <div className="certificate" style={{ maxHeight: '1000px', maxWidth: '1000px', margin: '50px auto', padding: '80px', border: '2px solid #000000', backgroundImage: `url(${newimg3} ) `, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: '50%', backgroundColor: '#28282818', borderRadius: '10px', textAlign: 'center'  , boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'}}>
        
        <div className="certificate-header" style={{ fontSize: '30px', fontWeight: 'bold' }}>Kataria & Associates</div>
        <div className="certificate-header" style={{ fontSize: '30px', fontWeight: 'bold' }}>Present a Certificate of Participation to</div>
        <div className="participant-name" style={{ fontSize: '20px', marginTop: '20px' }}>Ms/Mr  <b> {testusername} </b>  </div>
        <div className="participant-email" style={{ fontSize: '16px', marginTop: '10px' }}>E-mail : <b> {testuseremail} </b> </div>
        <div className="test-subject" style={{ fontSize: '18px', marginTop: '20px' }}>For participation in the online test on the subject of <b> Law for Arbitration in India</b> </div>
        <div className="score" style={{ fontSize: '18px', marginTop: '20px' }}>She/he has scored <b>  {score}</b>  out of 25.</div>
        <div className="congratulations" style={{ fontSize: '32px', marginTop: '20px', fontStyle: 'italic' }}>Congratulations</div>
      
        <div className="signatures" style={{ display: 'flex', justifyContent: 'space-between' }}>
        
          <div className="signature" style={{ flexBasis: '35%', fontSize: '16px' }}>
            <img src= {sanjeevsign}  alt ="Signature" style={{ maxWidth: '50%', height: '5rem' }} />
            <br /><br />
            <p style={{ textAlign: 'center', marginTop: '-1.5rem', fontStyle: 'italic' }}>
            Sanjeev Kumar<br />
            Advocate
            </p>
          </div>
        
          <div className="signature" style={{ flexBasis: '35%', fontSize: '16px' }}>
            <img src={prithvisign} alt="Signature" style={{ maxWidth: '40%', height: '6.2rem' , transform: 'rotate(43deg)' }} />
            <br /><br />
            <p style={{ textAlign: 'center', marginTop: '-3rem', fontStyle: 'italic' }}>
            Prithvish Rajamani<br />
            Advocate
            </p>
          </div>
        
        </div>
      
        <div className="firm-details" style={{ marginTop: '30px', fontSize: '16px' }}>
          Kataria & Associates<br />
          443, 443A, Civil Wing, Tis Hazari Courts, Delhi 110054<br />
          Whatsapp. 96507 56107, E-mail: cssanjeevkataria@gmail.com<br />
          Website: <a href="http://katariaandassociates.in">katariaandassociates.in</a>
        </div>
      
      </div>
    </div>

        </tr>
        <tr className='ccctr'>
          <td colSpan={2}>  <Button variant='success'  onClick={generatePDF}> download your certificate</Button> </td>
        
        </tr>
        <tr className='ccctr'>
          <td colSpan={2}>  <Link to="/"> <Button variant='primary'>   Go Home </Button>   </Link>  </td>
        </tr> 
      </tbody>   
    </Table> 
        </Modal.Body>
      </Modal>  



     
        </div>
    )
}

export default Quiz