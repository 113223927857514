// // import { position } from "html2canvas/dist/types/css/property-descriptors/position";
// import { useState ,useEffect} from "react";
// const slideStyles = {
//     width: "100%",
//     height: "100%",
//     borderRadius: "5px",
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     transition: "transform 5s ease-in",
//     boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
//     position: "absolute",
//   };
  
//   const rightArrowStyles = {
//     position: "absolute",
//     top: "50%",
//     transform: "translate(0, -50%)",
//     right: "32px",
//     fontSize: "45px",
//     color: "#fff",
//     zIndex: 1,
//     cursor: "pointer",
//   };
  
//   const leftArrowStyles = {
//     position: "absolute",
//     top: "50%",
//     transform: "translate(0, -50%)",
//     left: "32px",
//     fontSize: "45px",
//     color: "#fff",
//     zIndex: 1,
//     cursor: "pointer",
//   };
  
//   const sliderStyles = {
//     position: "relative",
//     height: "100%",
//     width: "100%",
//     overflow: "hidden",
//     borderRadius: "5px",
//     boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
//     zIndex: "-100",
//     margin: "10px 0",
//     padding: "0",
//     listStyle: "none",

   

//   };
  
//   const dotsContainerStyles = {
//       display: "flex",
//       justifyContent: "center",
      
//   };
  
//   const dotStyle = {
//     margin: "0 3px",
//     cursor: "pointer",
//     fontSize: "20px",
//     color: "#fff",
//   };
  

// function Imageslider({ slides }) {
//     const [currentIndex, setCurrentIndex] = useState(0);
//     const goToPrevious = () => {
//       const isFirstSlide = currentIndex === 0;
//       const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
//       setCurrentIndex(newIndex);
//     };
//     const goToNext = () => {
//       const isLastSlide = currentIndex === slides.length - 1;
//       const newIndex = isLastSlide ? 0 : currentIndex + 1;
//       setCurrentIndex(newIndex);
//     };
//     const goToSlide = (slideIndex) => {
//       setCurrentIndex(slideIndex);
//     };
//     const slideStylesWidthBackground = {
//       ...slideStyles,
//       backgroundImage: `url(${slides[currentIndex].url})`,
//     } 

//     useEffect(() => {
//             const intervalId = setInterval(goToNext, 3000);
//       return () => clearInterval(intervalId);
//     }, [currentIndex, slides]);

//   return (
//     <> 
//       <div style={sliderStyles}>
//       <div>
//         <div onClick={goToPrevious} style={leftArrowStyles}>
//           ❰
//         </div>
//         <div onClick={goToNext} style={rightArrowStyles}>
//           ❱
//         </div>
//       </div>
//       <div style={slideStylesWidthBackground}></div>
//       <div style={dotsContainerStyles}>
//         {slides.map((slide, slideIndex) => (
//           <div
//             style={dotStyle}
//             key={slideIndex}
//             onClick={() => goToSlide(slideIndex)}
//           >
//             ●
//           </div>
//         ))}
//       </div>
//     </div>
//     </>
//   )
// }

// export default Imageslider


// import { useState, useEffect } from "react";

// const slideStyles = {
//   width: "100%",
//   height: "100%",
//   borderRadius: "5px",
//   backgroundSize: "cover",
//   backgroundPosition: "center",
//   transition: "transform 1s ease-in-out", // Adjusted for smoother transition
//   boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
//   position: "absolute",

// };

// const rightArrowStyles = {
//   position: "absolute",
//   top: "50%",
//   transform: "translate(0, -50%)",
//   right: "32px",
//   fontSize: "45px",
//   color: "#fff",
//   zIndex: 1,
//   cursor: "pointer",
// };

// const leftArrowStyles = {
//   position: "absolute",
//   top: "50%",
//   transform: "translate(0, -50%)",
//   left: "32px",
//   fontSize: "45px",
//   color: "#fff",
//   zIndex: 1,
//   cursor: "pointer",
// };

// const sliderStyles = {
//   position: "relative",
//   height: "100%",
//   width: "100%",
//   overflow: "hidden",
//   borderRadius: "5px",
//   boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
//   zIndex: "-100",
//   margin: "10px 0",
//   padding: "0",
//   listStyle: "none",
//   transition: "transform 10s ease-in-out",

//   speed: "10000ms",

// };

// const dotsContainerStyles = {
//   display: "flex",
//   justifyContent: "center",
//   position: "relative",
//   bottom: "10px",
// };

// const dotStyle = {
//   margin: "0 3px",
//   cursor: "pointer",
//   fontSize: "20px",
//   color: "#fff",
// };

// function Imageslider({ slides }) {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const goToPrevious = () => {
//     const isFirstSlide = currentIndex === 0;
//     const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//   };

//   const goToNext = () => {
//     const isLastSlide = currentIndex === slides.length - 1;
//     const newIndex = isLastSlide ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//   };

//   const goToSlide = (slideIndex) => {
//     setCurrentIndex(slideIndex);
//   };

//   const slideStylesWidthBackground = {
//     ...slideStyles,
//     backgroundImage: `url(${slides[currentIndex].url})`,
//   };

//   useEffect(() => {
//     const intervalId = setInterval(goToNext, 3000); // Change slide every 3 seconds
//     return () => clearInterval(intervalId);
//   }, [currentIndex, slides]);

//   return (
//     <>
//       <div style={sliderStyles}>
//         <div onClick={goToPrevious} style={leftArrowStyles}>
//           ❰
//         </div>
//         <div onClick={goToNext} style={rightArrowStyles}>
//           ❱
//         </div>
//         <div style={slideStylesWidthBackground}></div>
//         <div style={dotsContainerStyles}>
//           {slides.map((slide, slideIndex) => (
//             <div
//               style={dotStyle}
//               key={slideIndex}
//               onClick={() => goToSlide(slideIndex)}
//             >
//               ●
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Imageslider;


import { useState, useEffect } from "react";
import { Transition } from "react-spring";

const slideStyles = {
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  zIndex: "-100",
  transition: "opacity 1s ease-in-out", // Changed to opacity for smoother transition
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
  // position: "absolute",
};

const rightArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  right: "32px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const leftArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  left: "32px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const sliderStyles = {
  // position: "relative",
  height: "100%",
  width: "100%",
  Transition: "opacity 5s ease-in-out",
  // transition: "transform 10s ease-in-out",
  transform: "translate3d(0, 0, 0)",

  transform: "translateX(0)",
  // overflow: "hidden",
  borderRadius: "0px",
  // boxShadow: " 0px  0px 40px 10px rgba(156, 166, 173), 0px 10px 0px 0px rgba(156, 166, 173)",
  zIndex: "-100",

  // listStyle: "none",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
  position: "relative",
  bottom: "10px",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
  color: "#fff",
};

function Imageslider({ slides }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[currentIndex].url})`,
  };

  useEffect(() => {
    const intervalId = setInterval(goToNext, 3000); // Change slide every 3 seconds
    return () => clearInterval(intervalId);
  }, [currentIndex, slides]);

  return (
    <>
      <div style={sliderStyles}>
        <div onClick={goToPrevious} style={leftArrowStyles}>
          ❰
        </div>
        <div onClick={goToNext} style={rightArrowStyles}>
          ❱
        </div>
        <div style={slideStylesWidthBackground}></div>
        <div style={dotsContainerStyles}>
          {slides.map((slide, slideIndex) => (
            <div
              style={dotStyle}
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
            >
              
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Imageslider;
