import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import rajamani from "../images/rajamani.jpg"
import { FaLinkedin, FaMailBulk,FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../App.css";

function Rajamani() {
  return (
    <div className="centerCol">
    <div className="welcomeText">
      <Container>
        <h3 className='center '><span>Our Advocate profile section</span></h3>
      </Container>

      <Container>
          <Row>
            <Col> <img src={rajamani} alt='werty'  className='m-2 imgcus1' />
              <div className='red12'>
                            <ul className='rowflex'>
                  <li className='advs'>
                  <Link to= "https://www.linkedin.com/in/advocate-anju-arora-514826150?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                 <FaLinkedin/></Link>
                  </li>
                  <li className='advs'>
                  <a href= 'mailto:prithvish@tranlife.in'>
                  <FaMailBulk/></a></li>
                  <li className='advs'><Link to = "https://wa.me/+916382221581?text=Hi+Advocate+Prithvish+I+am+interested+in+your+services+for+my+case+with+your+company+Kataria+and+Associates+"> <FaWhatsapp/></Link></li>

                
                </ul>
              </div>
              <Col>
             <p> 1. Chamber :</p>
             <h6  className='ttrr12'>Advocate Rajamani ji </h6>
             <h6  className='ttrr12'> 443, 443A, Civil Wing, Tis Hazari CourtDelhi 110 054</h6>
             <h6  className='ttrr12'>Delhi -54 </h6>
             <h6  className='ttrr12'> Mobile and what-app no. +91-9115-669-900,</h6>
             <h6  className='ttrr12'> Email: info@tranlife.in  </h6>
            </Col>
            
            <Col> 
            <p>   2. Office:</p>
<h6 className='ttrr12 '> Kataria and Associates,</h6>
<h6 className='ttrr12 '> 443, 443A, Civil Wing,</h6>
<h6 className='ttrr12 '> Tis Hazari Court</h6>
<h6 className='ttrr12 '> Delhi -54 </h6>
</Col>
            </Col>
            <Col className='m-2 il '>
              <h3> <b> Legal Background and Qualifications</b></h3>
       <h5>Prithvish Rajamani is one of the most sought-after family courts’ mediators in India with a success rate well above the industry average. With over 10 years’ experience and 80+ successful settlement agreements over 18,000 hrs., he has been recognised as one of the leading mediators in Bangalore for the 3 consecutive years. He also holds the record for the 2nd and 3rd highest settlements in Bangalore Mediation Center. He is accredited with Indian Institute of Arbitration & Mediation, Delhi (IIAM), Asia Pacific Centre for Arbitration & Mediation (APCAM), Standards & Competencies in Mediation Advocacy, London (SCMA) and The State Bar of California which makes him one of the most experienced mediation advocates in India.
        <br></br>
<br></br>
Prithvish is known for his relaxed and approachable manner alongside a keen analytical mind honed over the years which combined with his meticulous preparation, enable him to win the trust and confidence of the participants right from the start of the mediation process. His straight-talking, innovative style has led to resolution of all types of family disputes - from modest claims to complex settlements. As a skilled negotiator, Prithvish understands the psychology of conflict resolution, and is intuitively able to identify both - the barriers to the negotiation and the opportunities for advancing it in the client’s favour. Prithvish offers the participants wider perspectives on the dispute while focusing on their comprehensive interests and of their children whilst remaining tuned in to everyone’s sensitivities. He is highly adaptable in his approach, based on the dynamics at play in each mediation, and is appreciated for his transparency, energy and tenacity. He continues working with the participants over the days following the mediation also so that the momentum towards settlement is never lost.
<br></br>
<br></br>
In recognition of his contribution to the legal Industry, achievements, innovative approach and futuristic spirit he was awarded the Lex Falcon Global Award held in Singapore and the Outstanding Leadership Award at the LAW 2.0 Conference held in Dubai in the year 2022.
</h5>
          </Col>
          </Row>
         
        </Container>
    </div>
  </div>
  )
}

export default Rajamani