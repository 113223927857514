import { React, useEffect, useState } from "react";
import Carsol from "./Carsol"
// import carousel from "./carousel.js"
// import HomeCarousel from "./carousel.js";

import axios from 'axios';
// import LocomotiveScroll from 'locomotive-scroll';
import { Button, Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import CardData from "./CardData";
import cont1img from "../../images/call.png"
import BlogDetail from "../../pages/BlogDetail";
import { BACKEND_URL } from "../../services/helper.js";
import bg7 from "../../images/h6.png";
import resource from "../../images/resource.png"
import media from "../../images/media.png"
// import im5 from "../../images/im5.png"
import h1 from "../../images/h1.png"
// import h2 from "../../images/h2.png"
import { LazyLoadImage } from "react-lazy-load-image-component";




function Welcomw() {

  const [blogs, setBlogs] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState(4);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/blog/get_blogs`);
        setBlogs(response.data);
        setLoading(false); 
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []); 

  const handleReadMore = (blog) => {
    setSelectedBlog(blog);
  };

  const handleCloseModal = () => {
    setSelectedBlog(null);
  };


 



  return (
  
  <>
  <div className="" >
<div>

    <Container fluid>
      <Carsol fluid   style={{ width: "100%"  , Transition: "all 3s ease-in-out 3s"}} />

      {/* <HomeCarousel/> */}
    </Container>
 

    <Container fluid>
      <Row>
      </Row>
      <div className='border1 flex animated-background  '>
        <div className='div_box'>
          <div className='text_item '>
            <h1 className=""> <b>We are your partner to take care of your legal interest: <br /> Your Path to Justice.</b></h1>
            <p> Kataria and Associates is a human value legal firm, who puts client’s interest first. Our moto and way of working is to improve quality of life of the clients.</p>
           
          </div>
          <Link to="/Contact" style={{width:"200px", justifyContent:"center"}} >
            <Button className='m-1 sub-btn1 center'> Contact us →</Button>
            </Link>
        </div>
     
        <div className='box_home'>
       
          <LazyLoadImage
              alt="Resource"
              src={h1}
              effect="blur"
             
            />
       </div>
      </div>
      <div className=' fffht v'>
        <div >
        
          <LazyLoadImage
              alt="Resource"
              src={resource}
              effect="blur"
              className='box_home'
              // placeholderSrc={placeholderImage}
            />

        </div>
        <div className=''>
          <div className='text_item'>
            <h1 className="h1"> <b> Legal Resources for You</b></h1>
            <p> At Kataria and Associates, we believe in empowering individuals with access to valuable legal resources. Whether you're seeking information about your rights, legal procedures, or general legal knowledge, our resources page is designed to provide you with straightforward and easy-to-understand content. Explore the following topics to enhance your understanding of the law:</p>
            <Link to="/Resource">
            <Button className='m-3 sub-btn1 center '>Explore now →</Button>
            </Link>
          </div>

        </div>
      </div>
    </Container>
    </div>
    <Container>
      <h3 className="bbb1"> Our strength is doing Hard Work for you</h3>
      <div className="kmsxyz">
        <Row>
          <Col sm>
            <Card style={{ width: '15rem', height: "17rem" }} className="hov media_card">
              <Card.Body className="cardbody123">
                <Card.Title className="">Understanding the Facts:</Card.Title>
                <Card.Text >
                  <p>We spend time with your to understand the facts, their chronology, timeline and synopsis . This is to identity the disputes in your order of priorities. One the problem is well defined, the solutions merge from study of the same. </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm>
            <Card style={{ width: '15rem', height: " 17rem" }} className="hov media_card">
              <Card.Body className="cardbody123">
                <Card.Title className="">Understanding your legal objectives:</Card.Title>
                <Card.Text>
                It  is very important that we should know what legal goals your want to achieve,  what resources you can commit and what alternatives your prefer, mediation or litigation. 
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm>
            <Card style={{ width: '15rem', height: " 17rem" }} className="hov media_card">
              <Card.Body className="cardbody123">
                <Card.Title className=""> Legal Research and Draftings</Card.Title>
                <Card.Text>
                Once legal targets are decided, legal research into possible solution  follows. Our experience and skills in research as well as research material available with us puts us in excellent spot to serve you better. 
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm>
            <Card style={{ width: '15rem', height: " 17rem" }} className="hov media_card">
              <Card.Body className="cardbody123">
                <Card.Title className="">Management of Legal Processes:</Card.Title>
                <Card.Text>
                Our team of excellent legal experts is well experienced in managing legal processes to the best of your advantage. They make the legal processes efficient and least intrusive to your schedules. 
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm>
           
          </Col>

        </Row>  </div>
    </Container>
    <Container >
      <h3 className="bbb1" >Our Super-specialization areas</h3>
      <Row className="flexr">
      <Col className="">
      <div className=''>
          <div className='super-text'>
            <h2 className="h2"> Empowering Your Legal Targets</h2>
            <p className="">
We are a full services legal firm and take care of not only your legal needs but also side effects and other impacts of the disputes in your life. We serve as one destination stop for all of your disputes related problems. We provide legal services in wide range of subjects Please visit our services page for details..
24 X 7</p>
            <Link to="/Service">
            <Button className='m-3 sub-btn1' > Explore our services »</Button>
            </Link>
          </div>
        </div>

</Col>
        <Col sm={7} >
           <div className="super-flex" >
          <Row >
            <Col xs={6} md={3} className="card_body_tyu media_card mode-card">
              <div className="circle c1"></div>
            <center>  <h4 style={{
                fontSize: "16px",
              }}> Family Disputes </h4> </center>
            </Col>
            <Col xs={6} md={3} className="card_body_tyu media_card mode-card">
              <div className="circle c2"></div>
              <center> <h4 style={{
                fontSize: "16px",
              }}> Property Disputes</h4> </center>
            </Col>
            <Col xs={6} md={3} className="card_body_tyu media_card mode-card">
              <div className="circle c3"></div>
              <center> <h4 style={{
                fontSize: "16px",
              }}>Mediation</h4></center>
            </Col>
          </Row>
          <Row >
          <Col xs={6} md={3} className="card_body_tyu media_card mode-card">
              <div className="circle c5"></div>
              <center><h4 style={{
                fontSize: "16px",
              }}> Criminal law</h4></center>
            </Col>
            <Col xs={6} md={3} className="card_body_tyu media_card mode-card">
              <div className="circle c6"></div>
              <center> <h4 style={{
                fontSize: "16px",
              }}>Corporate Law</h4></center>
            </Col>
            <Col xs={6} md={3} className="card_body_tyu media_card mode-card">
              <div className="circle c4"></div>
              <center><h4 style={{
                fontSize: "16px",
              }}> Insolvency</h4></center>
            </Col>
          
          </Row>
         </div>
        </Col >
      </Row>
    </Container>

   
    <Container >
      <h3 style={{fontSize:"3rem ", marginBottom:"-3rem"
    }}className="bbb1"> Our Team</h3>
      <p style={{fontSize:"1.2rem ", fontWeight:"300"
    }} className="bbb1 ">Law Firm Kataria and Associates is a full-service law firm located in Delhi. We have been providing legal services to our clients for over 12 years. Our team of experienced attorneys is dedicated to providing our clients with the highest quality legal representation.</p>
      <Container> 
 <CardData/>
 </Container>
      <center className="m-2 ">  <Link to="/AdvocatePage"> <Button className="sub-btn1 m-5"> See more about Advocates →</Button></Link></center>
    </Container>
    <Container >
      <h3 className="b1"> Our Awards</h3>
      
<div className="flex">
      <Row className="krowe">
        <Card style={{ width: '8rem', height: "8rem", margin: "20px" }} className="bgc_gtr">
         <div className="rewad a1">  </div> 
        </Card>
        <Card style={{ width: '8rem', height: "8rem", margin: "20px" }} className="bgc_gtr">
 <div className="rewad a2">  </div>
        </Card>
        <Card style={{ width: '8rem', height: "8rem", margin: "20px" }} className="bgc_gtr">
 <div className="rewad a3">  </div>
        </Card>
        <Card style={{ width: '8rem', height: "8rem", margin: "20px" }} className="bgc_gtr">
 <div className="rewad a4">  </div>
        </Card>
  
      </Row>
      <Row className="krowe">
        <Card style={{ width: '8rem', height: "8rem", margin: "20px" }} className="bgc_gtr">
          <div className="rewad a6">  </div>
        </Card>
        <Card style={{ width: '8rem', height: "8rem", margin: "20px" }} className="bgc_gtr">
        <div className="rewad a7">  </div>
        </Card>
      
        <Card style={{ width: '8rem', height: "8rem", margin: "20px" }} className="bgc_gtr">
        <div className="rewad a9">  </div>
        </Card>
        <Card style={{ width: '8rem', height: "8rem", margin: "20px" }} className="bgc_gtr">
        <div className="rewad a0">  </div>
        </Card>
      </Row>
      
    </div>
    </Container>
    <div className= "fffht v">
    <div className=""> 
  
    
    <LazyLoadImage
              alt="Resource"
              src={bg7}
              effect="blur"
              className="Legal box_hom"

            />
    </div>
      <div className=" ">
     
        <div className="text_item ">
          <h5 className="h1"> <b>Legal Insights and Guidance </b></h5>
          <p className="">  Welcome to kataria and associate, our journey began with a passion for justice and a dedication to making a difference. With years of collective experience, our team of skilled lawyers brings a wealth of expertise to the table. We have successfully represented countless clients, standing up for their rights and ensuring fair outcomes.</p>
          <center className="m-3" >    
        
          <Link to="/Faculty" >  <Button className='ml-1 nnn btns' >Faculty » </Button>  </Link>
          <Link to="/Litigants"> <Button className='ml-3 nnn btns' >Litigants » </Button>  </Link>
          <Link to="/Student">    <Button className='ml-3 nnn btns'>Student » </Button>  </Link>
          <Link to="/SiteMap"> <Button className='ml-3 nnn btns'>Advocate » </Button>  </Link>
          <Link to="/Judiciary"> <Button className='ml-3 nnn btns'>Judiciary » </Button>  </Link>
          </center>
        </div>
      </div>
     
    </div>
    <div className="blog-container1"> 
      <h3 className="bbb1"> Blogs From Kataria and Associates    <Link to="/Blog" className="center" >
            <Button className='m-1 sub-btn1-none'> click here for More blogs⟫</Button>
            </Link> </h3>
    
     
          
      <Row>
        <Col className="">
          <Row className=" ">
          {loading ? ( // Display animated loader when loading
            <div className="loader"></div>
          ) : (
       <ul>
        {blogs.slice(0, visibleBlogs).map((blog) => (
          <li key={blog._id} className="blogcss">
            <h3>{blog.blogg_heading}</h3>
            {/* <p >{blog.blogg_paragraph}</p> */}
            
            <button onClick={() => handleReadMore(blog)} className="btnblog">Read More</button>
          </li>
        ))}
      </ul>
          )}
   
          
          </Row>
          
        </Col>
      </Row> 


      
    </div>
    

    <div className='fffht media_class'>
        <div className=''>
          
          <LazyLoadImage
              alt="Resource"
              src={media}
              effect="blur"
              className="Legal box_home "
              // placeholderSrc={placeholderImage}
            />
        </div>
        <div className='div_box'>
          <div className=' text_item'>
            <h4 className="h1"> <b>Capturing Moments:</b> <br></br>Our Event Media Gallery</h4>
            <p>Step into the vibrant world of Kataria and Association through our event media gallery. We believe in celebrating moments that define our journey, and we invite you to explore the essence of our events through captivating images. From community outreach to legal seminars, each picture tells a story of our commitment to connection and engagement.</p>   
            <Link to="/Media">     <Button className='m-3 sub-btn1'> Explore Our Media Gallary »</Button> </Link>
          </div>
        </div>
      </div>      
      
     
    <Container className="center ">
      <Row className="rtyrev">
        <Col xs={12} md={8} className="newtxt text_item">
          <center> <h3 className="">
          Any Query?
          </h3></center>
          <center>        <p>Request for a personalized budget for your legal problem. We will send you a couple of options in 24 hours. You can have free consult , if you are our first customer of the day.</p>
          </center>
          <center>  <a href='tel:9650756107'> <Button className="btns"> Call now »</Button></a></center>
        </Col>
        <Col >
        
          <LazyLoadImage 
              alt="Resource"
              src={cont1img}
              effect="blur"
              className=" box_hom"
      
            />
        </Col>
      </Row>
    </Container> 
    {selectedBlog && <BlogDetail isOpen={!!selectedBlog} onClose={handleCloseModal} blog={selectedBlog} />}
    </div>
  </>
  )
}




export default Welcomw