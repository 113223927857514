

// import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import Accordion from 'react-bootstrap/Accordion';
// import { Link } from 'react-router-dom';
// import {BACKEND_URL} from "../services/helper.js"



// function Resource() {  
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [resources, setresources] = useState([]);
//   // const [paragraphs, setParagraphs] = useState([]);
//   const [selectedParagraph, setSelectedParagraph] = useState();
//   const [categorizedData, setCategroizedData] = useState({});
//   const [loading, setLoading] = useState(true);

//   console.log(categorizedData);


//   console.log("selectedParagraph",selectedParagraph)
//   useEffect(() => {
//     // Fetch blogs from the API endpoint
//     const fetchResource = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/resource/get_resources`);
//         setresources(response.data);
//         console.log(response.data)

//         const data = {};

//         response?.data?.forEach(item => {
//             const { _id, resource_heading, resource_paragraph, resource_category } = item;
            
//             if (!data[resource_category]) {
//                 data[resource_category] = [];
//             }
            
//             data[resource_category].push({ _id, resource_heading, resource_paragraph });
//         });


//         setCategroizedData(data);
//         setLoading(false);
       

//       } catch (error) {
//         console.error('Error fetching blogs:', error);
//       }
//     };
  
//     fetchResource();
//   }, []);   

//   return (

//     <>
//   <div className='respad'>
//     <div className="centerCol">
//       <div className="welcomeText">
//         <h3>Resource</h3>
//         <p>
//           <b>Note:</b> Should readers require material information on any topic of their choice, please write to us and send your payment order, the same will be made available within one week. Next day services also available at double the payment.
//         </p>
//         {loading ? ( // Display animated loader when loading
//             <div className="loader"></div>
//           ) : (
        
        
//         Object.keys(categorizedData).map((category, index) => (
//           <Accordion key={index}>
//             <Accordion.Item eventKey={index.toString()}>
//               <Accordion.Header>{category}</Accordion.Header>
//               <Accordion.Body>
//                 {categorizedData[category].map(resource => (
//                   <p key={resource._id} style={{textAlign: "left"}}>
//                     <Link
//                         key={resource._id}
//                         to={{
//                           pathname: `/resource/${resource._id}`
//                         }}
//                         className="resource-link"
//                       >
//                         {resource.resource_heading} 
//                       </Link>
//                   </p>
//                 ))}
//               </Accordion.Body>
//             </Accordion.Item>
//           </Accordion>
//         )
//         ))}
//       </div>
//     </div>
//   </div>
// </>


//   )
// }

// export default Resource


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Accordion from 'react-bootstrap/Accordion';
// import { Link } from 'react-router-dom';
// import { BACKEND_URL } from "../services/helper.js"

// function Resource() {
//   const [, setResources] = useState([]);
//   const [categorizedData, setCategorizedData] = useState({});
//   const [loading, setLoading] = useState(true);
//   // const resource = 0;
//   // let index = 0;

//   useEffect(() => {
//     const fetchResources = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/resource/get_resources`);
//         const data = {};
//         response.data.forEach(item => {
//           const { _id, resource_heading, resource_paragraph, resource_category } = item;
//           if (!data[resource_category]) {
//             data[resource_category] = [];
//           }
//           data[resource_category].push({ _id, resource_heading, resource_paragraph });
//         });
//         setResources(response.data);
//         setCategorizedData(data);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching resources:', error);
//       }
//     };
//     fetchResources();
//   }, []);

//   const i = 1;
//   return (
//     <div className='respad'>
//       <div className="centerCol">
//         <div className="welcomeText">
//           <h3>Resource</h3>
//           <p>
//             <b>Note:</b> Should readers require material information on any topic of their choice, please write to us and send your payment order, the same will be made available within one week. Next day services also available at double the payment.
//           </p>
//           {loading ? (
//             <div className="loader"></div>
//           ) : (
//             Object.keys(categorizedData).map((category, index) => (
//               <Accordion key={index}>
//                 <Accordion.Item eventKey={index.toString()}>
//                   <Accordion.Header>{category}</Accordion.Header>
//                   <Accordion.Body>
//                     {categorizedData[category].map(resource => (
//                       <p key={resource._id} style={{ textAlign: "left" }}>
//                         <Link 
//                           to={`/resource/${resource._id}`}
//                           className="resource-link"
//                         >
//                      {index++} {resource.resource_heading}
//                         </Link>
//                       </p>
//                     ))}
//                   </Accordion.Body>
//                 </Accordion.Item>
//               </Accordion>
//             ))
//           )}
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Resource;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { BACKEND_URL } from "../services/helper.js"

function Resource() {
  const [, setResources] = useState([]);
  const [categorizedData, setCategorizedData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/resource/get_resources`);
        const data = {};
        response.data.forEach(item => {
          const { _id, resource_heading, resource_paragraph, resource_category } = item;
          if (!data[resource_category]) {
            data[resource_category] = [];
          }
          data[resource_category].push({ _id, resource_heading, resource_paragraph });
        });
        setResources(response.data);
        setCategorizedData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };
    fetchResources();
  }, []);

  return (
    <div className='respad'>
      <div className="centerCol">
        <div className="welcomeText">
          <h3>Resource</h3>
          <p>
            <b>Note:</b> Should readers require material information on any topic of their choice, please write to us and send your payment order, the same will be made available within one week. Next day services also available at double the payment.
          </p>
          {loading ? (
            <div className="loader"></div>
          ) : (
            Object.keys(categorizedData).map((category, categoryIndex) => (
              <Accordion key={categoryIndex}>
                <Accordion.Item eventKey={categoryIndex.toString()}>
                  <Accordion.Header>{category}</Accordion.Header>
                  <Accordion.Body>
                    {categorizedData[category].map((resource, resourceIndex) => (
                      <p  className="resource-link" key={resource._id} style={{ textAlign: "left" }}>
                        <Link 
                          to={`/resource/${resource._id}`}
                          className="resource-link"
                        >
                          {resourceIndex + 1} {resource.resource_heading}
                        </Link>
                      </p>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default Resource;
