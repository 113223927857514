import React from 'react'
import Imageslider from "./Imageslider"
import hslider1 from "../../images/slider-1.JPG"
import hslider2 from "../../images/slider-2.jpg"

const slides = [
  
    { url:hslider1 , title: "beach" },
    { url: hslider2, title: "boat" },
  ];
 
function Carsol() {
  return (
   <div >
      <div className='carouselbox rcarouselbox'>
        <Imageslider slides={slides} />
      </div>
    </div>
  )
}

export default Carsol